import React from 'react';
import { useTranslation } from "react-i18next";
import Animate from "components/animate/animate";
import './style.scss'

const ReservationList = () => {
  const { t } = useTranslation();

  return (
    <Animate>
      <div className="reservation-list">
        <div className="card">
          <div className="card-title">
            <h1>{t("reservation_page.list.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <ul className="list-group">
              <li className="list-group-item">
                <p className="place">渋谷店</p>
                <p className="time">14:00 ~ 14:30</p>
                <p className="date">05/15</p>
              </li>
              <li className="list-group-item">
                <p className="place">中野</p>
                <p className="time">14:00 ~ 14:30</p>
                <p className="date">05/15</p>
              </li>
              <li className="list-group-item">
                <p className="place">三軒茶屋</p>
                <p className="time">14:00 ~ 14:30</p>
                <p className="date">05/15</p>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </Animate>
  )
};

export default ReservationList;