import React, { useState, useEffect, useContext, useCallback } from "react";
import Animate from "components/animate/animate";
import "./style.scss";
import { Get_Warranties } from "services/Warranties";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";


const Warranty = () => {
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [useWarranties, setWarranties] = useState([]);
  const [usePageInfo, setPageInfo] = useState({});
  const [usePager, setPager] = useState([]);
  const [mounted, setMounted] = useState(true)


  const formatDate = (d) => {
    let date = new Date(d)
    let month = function() {
      let m = date.getMonth();
      let str = m < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
      return str;
    }
    let day = () => {
      let d = date.getDate();
      let str = d < 10 ? `0${d}` : d;
      return str;
    }
    let string = `${date.getFullYear()}.${month()}.${day()}`;
    return string;
  }

  const getWarranties = useCallback(async (page, details) => {
    console.log(details);
    let userId;
    userId = details?.identities?.find(x => { return x.provider === 'festaria' })?.user_id;
    if (!userId) {
      userId = details.festaria_id;
    }
    if (!userId) {
      return;
    }
    // userId = "300000000074"
    const warranties = await Get_Warranties(userId, page, 10);
    console.log('warranties:', warranties);
    setWarranties(warranties.data);
    setPageInfo(warranties.pageInfo);
    console.log(Array(warranties.pageInfo.pages));
    const pager = [];

    // 全部で「…含めて7個の要素に収まる」
    // 全部の要素が7個以下 => 全部表示
    // …8個以上 => 下記ロジックに従う
    // 全部で19ページの場合(pageInfo.pages = 19)
    // 1~4 => 1,2,3,4,5 … 19
    // 5~15 => 1 … 4,5,6 … 19
    // 16 => 1 … 15,16,17,18,19

    // 仮に8個だった場合
    // 1~4 => 1,2,3,4,5 … 8
    // 5 => 1 … 4,5,6,7,8


    if (warranties.pageInfo.pages <= 7) {
      // 要素数が7個以下
      for (var i = 0; i < warranties.pageInfo.pages; i++) {
        pager.push(i + 1);
      }
    } else {
      // 要素数が7個以上
      // pager.push(1);

      if (page <= 3) {
        for (var i = 1; i < 6; i++) {
          pager.push(i);
        }
        pager.push(0);
        pager.push(warranties.pageInfo.pages);
      } else if (page >= warranties.pageInfo.pages - 3) {
        pager.push(1);
        pager.push(0);
        for (var i = -4; i < 1; i++) {
          pager.push(warranties.pageInfo.pages + i);
        }
      } else {
        // 1 … x,y,z … 最終ページ
        pager.push(1);
        pager.push(0);
        for (var i = -1; i < 2; i++) {
          pager.push(page + i);
        }
        pager.push(0);
        pager.push(warranties.pageInfo.pages);
      }
    }
    setPager(pager);
  }, [])
  const onPageBack = useCallback(async () => {
    if (usePageInfo.page === 1) {
      return;
    }
    getWarranties(usePageInfo.page - 1, profileDetails)
  }, [usePageInfo, profileDetails, getWarranties])
  const onPageNext = useCallback(async () => {
    if (usePageInfo.page === usePageInfo.pages) {
      return;
    }
    getWarranties(usePageInfo.page + 1, profileDetails)
  }, [usePageInfo, profileDetails, getWarranties])
  const onPageMove = useCallback(async (page) => {
    if (usePageInfo.page === page ||
      page === 0) {
      return;
    }
    getWarranties(page, profileDetails)
  }, [usePageInfo, profileDetails, getWarranties])
  useEffect(() => {
    if (mounted) {
      getWarranties(1, profileDetails)
        .catch(err => console.log('error in getWarranties', err));
    }

    return () => setMounted(false);
  }, [profileDetails, getWarranties, mounted]);
  return (
    <Animate>
      <div className="warranty-page">
        <div className="card">
          <div className="card-title">
            <h1>品質証明書</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className={useWarranties.length === 0 ? 'show' : 'hide'}>
              品質証明書が存在しません。
            </div>
            {useWarranties.map((warranty, idx) => (
              <div key={`${warranty.product_name}-${idx}`} className="warranty">
                <h1 className="title">{warranty.product_name}</h1>
                <div className="info">
                  <p>注文番号 {warranty.order_no}</p>
                  <div className="info-middle">
                  <p>お買い上げ日 {formatDate(warranty.purchase_date)}</p>
                    <p>品番 {warranty.hinban}</p>
                    <p>サイズ {warranty.product_size}</p>
                    <p>SKU {warranty.sku}</p>
                  </div>
                  {/* <div className="info-bottom">
                    <p>有効期限 {warranty.expirationDate}</p>
                    {warranty.expiresSoon && (
                      <p className="expires-soon">保証期間終了</p>
                    )}
                  </div> */}
                </div>
              </div>
            ))}
            <div className={useWarranties.length === 0 ? 'hide' : 'pagenation'}>
              <div className="back">
                <button type="button" className="btn btn-back" onClick={() => onPageBack()}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </div>
              {usePager.map((pageNum, idx) => (
                <div key={`${idx}`} className="page-number">
                  <button type="button" className={usePageInfo.page === pageNum ? 'btn active' : 'btn'} onClick={() => onPageMove(pageNum)}>
                    {pageNum === 0 ? '…' : pageNum}
                  </button>
                </div>
              ))}
              <div className="next">
                <button type="button" className="btn btn-next" onClick={() => onPageNext()}>
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default Warranty;