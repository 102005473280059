import axios from "axios";

export const ACTION_GET = (url, config) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ ...err?.response?.data, status: err?.response?.status });
      });
  });
};

export const ACTION_POST = (url, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ ...err })
      });
  });
};

export const ACTION_PUT = (url, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject({ ...err })
      });
  });
};
