import React, { useState, useEffect, useContext, useCallback } from "react";
import Animate from "components/animate/animate";
import "./style.scss";
import {Get_Orders} from "services/Orders";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

const OrderHistory = () => {
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [useOrders, setOrders] = useState([]);
  const [usePageInfo, setPageInfo] = useState({});
  const [usePager, setPager] = useState([]);
  const [mounted, setMounted] = useState(true)
  
const getOrders = useCallback(async (page,details) => {
  console.log(details);
  let body = {}
  body.userId = details?.identities?.find(x => { return x.provider === 'festaria'})?.user_id;
  if (!body.userId) {
    body.userId = details.festaria_id;
  }
  if (!body.userId) {
    return;
  }
  body.page = page || 1;
  // body.userId = "300000000074"
  const orders = await Get_Orders(body);
  console.log('orders:', orders);
  setOrders(orders.data);
  setPageInfo(orders.pageInfo);
  console.log(Array(orders.pageInfo.pages));
  const pager = [];
  for(var i = 0;i< orders.pageInfo.pages;i++){
    pager.push(i);
  }
    setPager(pager);
}, [])
const onPageBack = useCallback(async () =>{
  if(usePageInfo.page === 1){
    return;
  }
  getOrders(usePageInfo.page - 1,profileDetails)
}, [usePageInfo,profileDetails,getOrders])
const onPageNext = useCallback(async () =>{
  if(usePageInfo.page === usePageInfo.pages){
    return;
  }
  getOrders(usePageInfo.page + 1,profileDetails)
}, [usePageInfo,profileDetails,getOrders])
const onPageMove = useCallback(async (page) =>{
  if(usePageInfo.page === page){
    return;
  }
  getOrders(page,profileDetails)
}, [usePageInfo,profileDetails,getOrders])
  useEffect(() => {
    if(mounted) {
      getOrders(1,profileDetails)
        .catch(err => console.log('error in getOrders', err));
    }
  
    return () => setMounted(false);
  }, [profileDetails, getOrders, mounted]);

  const formatDate = (d) => {
    let date = new Date(d)
    let month = function() {
      let m = date.getMonth();
      let str = m < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
      return str;
    }
    let day = () => {
      let d = date.getDate();
      let str = d < 10 ? `0${d}` : d;
      return str;
    }
    let string = `${date.getFullYear()}.${month()}.${day()}`;
    return string;
  }

  const Products = ({props}) => {
    const normalPrice = props?.item?.price + props?.item?.tax;
    const totalPrice = normalPrice - (props?.item?.return_product_back_price || 0) - (props?.item?.return_product_back_tax || 0)
    let quantity = props?.item?.product_number - props?.item?.product_back_number;
    if ( props?.item?.return_product_back_number > 0 ) {
      quantity = props?.item?.product_number - props?.item?.return_product_back_number;
    }
    return (
      <div key={`${props?.item?.product_name}-${props?.idGroup}-${props?.id}`} className="purchase">
        <h1 className="title">{props?.item?.product_name}</h1>
        <div className="info">
          <div className="row">
            <div className="col">
              <div>金額: ￥{normalPrice?.toLocaleString()}</div>
              <div>数量: {props?.item?.product_number?.toLocaleString()}</div>
            </div>
            {
              (props?.item?.type !== "EC") && props?.item?.product_name.includes("返品済み") ?
              <div className="col">
                <div>返品後金額: ￥{totalPrice?.toLocaleString()}</div>
                <div>返品後数量: {quantity?.toLocaleString()}</div>
              </div> : 
              <div className="col"></div>
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <Animate>
      <div className="order-history-page">
        <div className="card">
          <div className="card-title">
            <h1>購入履歴</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className={useOrders.length === 0 ? 'show' : 'hide'}>
            購入履歴が存在しません。
            </div>
            {useOrders.map((purchaseGroup, idx) => (
              <div key={`${purchaseGroup.purchaseDate}-${idx}`} className="purchase-group">
                <div className="purchase-group-divider">
                  <p className="location">{purchaseGroup.store_name ? purchaseGroup.store_name :'購入店舗不明'}</p>
                  <p className="date">お買い上げ日 {formatDate(purchaseGroup.purchase_date)}</p>
                </div>       
                {purchaseGroup.items.map((purchase, index) => (
                  <Products props={{id: index, idGroup: idx, item: purchase}} />
                ))}
              </div>
            ))}
            <div className={useOrders.length === 0 ? 'hide' : 'pagenation'}>
            <div className="back">
                    <button type="button" className="btn btn-back" onClick={() => onPageBack()}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </div>
                  {usePager.map((_, idx) => (
              <div key={`${idx}`} className="page-number">
                <button type="button" className={usePageInfo.page === idx + 1?'btn active':'btn'} onClick={() => onPageMove(idx + 1)}>
                        {idx + 1}
                </button>  
              </div>
            ))}
            <div className="next">
                    <button type="button" className="btn btn-next" onClick={() => onPageNext()}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default OrderHistory;