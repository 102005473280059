import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animate from "components/animate/animate";
import './style.scss'

const ReservationConfirm = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [furigana, setFurigana] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const getData = (data) => {
    setName(data.name)
    setFurigana(data.furigana)
    setPhone(data.phone)
    setEmail(data.email)
    setComments(data.comments)
  }

  useEffect(() => {
    if(props && props.location && props.location.state && props.location.state.data) {
      getData(props.location.state.data)
    }
  }, [props])

  return (
    <Animate>
      <div className="reservation-confirm">
        <div className="card">
          <div className="card-title">
            <h1>{t("reservation_page.confirm.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className="subtitle">{t("reservation_page.confirm.subtitle")}</div>
            <ul className="list-group">
              <li className="list-group-item">
                <div className="label">{t("reservation_page.lbl_name")}</div>
                <div className="value">{name}</div>
              </li>
              <li className="list-group-item">
                <div className="label">{t("reservation_page.lbl_furigana")}</div>
                <div className="value">{furigana}</div>
              </li>
              <li className="list-group-item">
                <div className="label">{t("reservation_page.lbl_phone")}</div>
                <div className="value">{phone}</div>
              </li>
              <li className="list-group-item">
                <div className="label">{t("reservation_page.lbl_email")}</div>
                <div className="value">{email}</div>
              </li>
              <li className="list-group-item">
                <div className="label">{t("reservation_page.lbl_comments")}</div>
                <div className="value">{comments}</div>
              </li>
            </ul>

            <div className="action-area">
              <div className="back">
                <Link to="/reservation">
                  <button type="button" className="btn btn-back">
                    <i className="fas fa-chevron-left"></i>
                  </button>
                </Link>
              </div>
              <div className="submission">
                <Link to="/reservation-final">
                  <button className="btn btn-save">{t("reservation_page.confirm.next")}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
};

export default ReservationConfirm;