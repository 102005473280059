import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripe_key = "pk_test_51JEtIRKkJU6rCqO1YDUsAsJ1ax0WifzcsO5T08tQkh580TyNGLZzG7criKBVCMpoXHuOo9VvEa0qTh3OGqi889in00nsex9VSK";
const stripeTestPromise = loadStripe(stripe_key);

const StripeContainer = (props) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm data={props} />
    </Elements>
  );
};

export default StripeContainer;
