import React, { useState } from "react";
import Animate from "components/animate/animate";
import BackHome from "components/backhome-button";
import StripeContainer from "components/stripe-payment/StripeContainer";
// import { GET_NFT_List } from "services/NFT";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { appsItem, DetailsItem } from "./items";
import "./style.scss";

const MySwal = withReactContent(Swal);

const NFT = () => {
  const [selectedCard] = useState("All Items");
  const [activeApp] = useState("");
  // const [cardsItem, setCardsItem] = useState([]);
  const [setShowPayment] = useState(true);

  // useEffect(() => {
    // const getNFT = async () => {
    //   // const details = await GET_NFT_Details("c31b6faa-c23b-4859-81e1-e0ecca75c13e");
    //   const list = await GET_NFT_List();
    //   if (list) {
    //     console.log("list", list);
    //     setCardsItem(list.data);
    //   }
    // };
    // getNFT();

    // if (!showPayment) {
    //   MySwal.close();
    // }
  // }, [showPayment]);

  // const setItemsByApps = (apps) => {
  //   const items = allCardsItem.filter((el) => el.apps === apps);
  //   const chosen = appsItem.filter((el) => el.apps === apps)[0];
  //   setActiveApp(chosen.apps);
  //   // setCardsItem(items);
  //   setSelectedCard(chosen.name);
  // };

  const onCancel = () => {
    setShowPayment(false);
  };

  const showDetails = async (data) => {
    const dataContent = DetailsItem(data);
    const { value: purchase } = await MySwal.fire({
      html: dataContent,
      confirmButtonText: "Purchase",
    });

    if (purchase) {
      setShowPayment(true);
      MySwal.fire({
        html: <StripeContainer onCancelClick={onCancel} itemInfo={data} />,
        showConfirmButton: false,
        allowOutsideClick: true,
      });
    }
  };

  return (
    <Animate>
      <div className="nft-page">
        <div className="card token">
          <div className="card-title">
            <h2>NFT</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className="connected-apps">
              <div className="label">Connected apps</div>
              <div className="group-items app-items row">
                {appsItem.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`item col-md-4 ${
                        item.apps === activeApp ? "active" : ""
                      }`}
                      // onClick={() => setItemsByApps(item.apps)}
                      onClick={() => showDetails(item)}
                    >
                      <img src={item.image} alt="item-icon" />
                    </div>
                  );
                })}
              </div>
              <div className="divider"></div>
              <div className="label-description">
                <div className="selected-title">{selectedCard}</div>
                <div className="total">
                  {/* Total: <span>{cardsItem.length}</span> */}
                </div>
              </div>
              {/* <div className="group-items card-items row">
                {cardsItem.length > 0 ? (
                  cardsItem.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="item col"
                        onClick={() => showDetails(item)}
                      >
                        <img src={item.image} alt="item-icon" />
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="loading-status">
                      <i className="fas fa-hourglass-half"></i>
                      <span>Loading Data . . .</span>
                    </div>
                  </>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <BackHome />
      </div>
    </Animate>
  );
};

export default NFT;
