import React, { createContext } from "react";

export const AuthConfigContext = createContext();

const AuthConfigProvider = ({authConfig, children}) => (
  <AuthConfigContext.Provider value={authConfig} >
    {children}
  </AuthConfigContext.Provider>
);

export default AuthConfigProvider;
