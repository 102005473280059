import React from 'react';
import "./style.scss"

const GrayBar = ({children}) => {
  return (
    <div className="gray-bar">
      {children}
    </div>
  );
}

export default GrayBar;
