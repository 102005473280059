import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

export const TicketInformation = ({event, price}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-100 d-flex flex-column align-items-start pb-4 px-4">
        <p className="fs-5 fw-bold">{t("tickets_page.ticket_page.ticket_info.info")}</p>
        <Card className="w-100 d-flex align-items-start py-4 gap-2 shadow border-0" style={{borderRadius: "16px"}}>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start" style={{textAlign: "left"}}>
                <i className="fas fa-newspaper"></i>
                <span className="fs-12 px-1">{event.description}</span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="fas fa-envelope"></i>
                <span className="fs-12 px-1">{event.email}</span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="fas fa-money-bill"></i>
                <span className="fs-12 px-1">{`${price.currency} ${price.amount}`}</span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="fas fa-home"></i>
                <span className="fs-12 px-1" style={{textAlign: "left"}}><a href={event.home_link}>{event.home_link}</a></span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="fas fa-map-marker-alt"></i>
                <span className="fs-12 px-1" style={{textAlign: "left"}}>{event.location.formatted_address}</span>
              </span>
            </Col>
          </Row>
        </Card>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-3 gap-3">
        <span><a href="https://facebook.com"><i className="fab fa-facebook"></i></a></span>
        <span><a href="https://twitter.com"><i className="fab fa-twitter"></i></a></span>
      </div>
    </>
  )
}

export default TicketInformation;