import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Animate from "components/animate/animate";
import "./style.scss";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";
import { getAnyOfValue } from "../../utils/getCustomDataValues"

const Agreement = () => {
  const { t } = useTranslation();
  const authConf = useContext(AuthConfigContext);
  const [formData, setFormData] = useState({});
  const [displayInfo, setDisplayInfo] = useState([]);
  const [profileDetails] = useContext(ProfileDetailsContext);
  useEffect(() => {
  }, [profileDetails, formData, authConf]);

  return (
    <Animate>
      <div className="agreement-page">
        <div className="card">
          <div className="card-title">
            <h1>フェスタリア メンバーズクラブ 利用規約</h1>
            {/* <Link className="edit" to="/profile/edit">
              <i className="fas fa-pen"></i>
              {t("profile_page.btn_edit")}
            </Link> */}
            <div className="title-bar"></div>
          </div>
          <div className="card-body">


            <p>第１条(会員の資格)</p>
            <p>1.本会の会員とは、株式会社サダマツ(以下、｢当社｣という)が運営する｢フェスタリア メンバーズクラブ｣の利用規約(以下、｢本規約｣という)を承認のうえ、会員登録を申し込まれ、所定の手続きを完了された方をいいます。なお、会員登録の申し込みは本人のみが行うものとし、代理での登録は行えないものとします。</p>
            <p>2.会員登録手続きは、前項の申し込みに対して当社が承諾したときに完了するものとします。ただし、次のいずれかに該当する場合には、当社は会員の登録申し込みを承諾しないか、承諾後であっても承諾の取り消しを行う場合があります。</p>
            <p>(1) 申込者が虚偽の事実を申告した場合</p>
            <p>(2) 第11条第1項に定める禁止事項に該当した場合</p>
            <p>(3) 過去に本規約違反等を理由として、会員登録の取り消し等が行われていたことが判明した場合</p>
            <p>(4) 第三者からの委託を受けて申し込みをした場合</p>
            <p>(5) その他、当社が会員として不適当と判断した場合</p>
            <p></p>
            <p>第2条(対象店舗)</p>
            <p>会員特典は、株式会社サダマツが運営するフェスタリア メンバーズクラブ取扱店舗および公式オンラインショップで利用することができます。</p>
            <p></p>
            <p>第3条(会員IDの発行等)</p>
            <p>1.当社は、会員に対して会員IDを発行するものとします。会員IDは、会員1人につき1つとし、複数保有できないものとします。</p>
            <p>2.会員は、対象店舗にてスマートフォン等で会員IDの画面を提示することにより、または、公式オンラインショップにて会員IDを利用してログインすることにより、会員特典等を受けることができるものとします。</p>
            <p>3.会員証は、会員本人のみが利用できるものとし、第三者に会員IDを譲渡または貸与できないものとします。</p>
            <p></p>
            <p>第4条(会員特典)</p>
            <p>1.会員は対象店舗及び公式オンラインショップにおいて、対象商品をご購入の際にメンテナンスサービス(商品保証)などの会員特典を利用することができます。会員特典の内容詳細は会員専用ページをご確認ください。</p>
            <p>2.会員特典は、事前に通知することなく変更、終了または停止する場合があります。</p>
            <p>3.前項の変更、終了または停止により会員に不利益または損害が生じた場合でも、当社はこれらについて一切責任を負わないものとします。</p>
            <p></p>
            <p>第5条（ポイントの取得、使用方法等）</p>
            <p>1.会員は、会員専用ページにログインした後の管理画面において、ポイントの取得状況や残高変更の履歴などを管理することができ、当社が定める条件を満たすことで、ポイントを無償で取得することができます。当社は、本規約で規定する禁止行為又は自動的に動作するプログラム等の使用、その他の不正な方法により、ポイントを取得することを禁じます。また、ポイントの使用期限は、当該ポイントの付与日から１年間とします。</p>
            <p>2.　会員は、ポイントを使用することで、当社又は当社が指定する第三者が提供するサービスを通じて、その他特典（以下併せて「特典」といいます。）を受けることができます。会員がポイントを使用する場合には、管理画面上の表示に従い、ポイントを使用するものとします。</p>
            <p>3.　ポイントを換金すること、およびポイントを第三者に譲渡することはできないものとします。</p>
            <p>４．ポイントは、ポイント使用時において、当社が定める条件を満たしていれば、特典を受けることができる可能性を表象しているものであり、ポイントの取得によって、会員と当社との間に具体的な権利義務が発生するわけではありません。本サービス又は特典は、将来的に変更・廃止される可能性があり、ポイント取得時に想定されていたポイントの使用については保証しません。</p>
            <p>５.ポイントの管理及び使用は、会員の責任において行われるものとします。ポイントの使用について、いかなる理由でも、使用後のキャンセルやポイントの再発行等の措置を取ることはできません。会員以外の第三者により不正使用され、そのために会員のポイントが消失した場合であっても、ポイントの再発行はできません。</p>
            <p>６.会員が、以下の各号に該当する場合、当社は、当該会員に通知することなく、ポイントを失効させることができるものとし、それによって当該会員が被った一切の損害について、責任を負わないものとします。1.本規約に違反し又はそのおそれがある場合、2.当社が定める有効期限を経過した場合</p>
            <p></p>
            <p>第6条(登録内容の変更)</p>
            <p>1.会員は、登録した個人情報に変更があった場合は、すみやかに会員専用ページにて変更手続きを行うものとし、対象店舗での変更手続きは行わないものとします。</p>
            <p>2.当社は、会員が変更登録を行わなかったことにより何らかの損害が生じたとしても、一切責任を負わないものとします。</p>
            <p></p>
            <p>第7条(LINE IDおよびパスワード)</p>
            <p>1.LINE ID(メールアドレス)およびパスワードの管理および使用は会員の責任とし、使用上の過誤または第三者の不正な使用等については、当社は一切その責任を負わないものとします。</p>
            <p>2.会員は、LINE ID およびパスワードが第三者に使用されていることが判明した場合には、直ちに当社に連絡するものとします。</p>
            <p></p>
            <p>第8条(会員情報の取扱い)</p>
            <p>1.会員登録時に会員より提供された個人情報は、当社が所有するものとします。</p>
            <p>なお、以下(1)～(8)、(12)の項目は会員登録時に必須の項目となります。</p>
            <p>(1) 氏名</p>
            <p>(2) 性別</p>
            <p>(3) 生年月日</p>
            <p>(4) メールアドレス</p>
            <p>(5) パスワード</p>
            <p>(6) 郵便番号</p>
            <p>(7) 住所</p>
            <p>(8) 電話番号</p>
            <p>(9) 未婚/既婚</p>
            <p>(10) 結婚記念日</p>
            <p>(11) 職業</p>
            <p>(12) DM送付の可否</p>
            <p>(13)メールマガジン無料購読の有無</p>
            <p>2.当社は会員の個人情報を以下の利用目的にのみ使用し、その他の目的では使用いたしません。</p>
            <p>(1) 会員専用サービス(保証および、アフターサービス)の提供のため</p>
            <p>(2) 商品・キャンペーン・施策のご案内のため</p>
            <p>(3) 会員認証および会員管理のため</p>
            <p>(4) 特典付与のため</p>
            <p>(5) アンケート調査実施のため</p>
            <p>(6) 商品開発または顧客満足度向上策検討のため</p>
            <p>(7) マーケティング分析のため</p>
            <p>(8) その他必要事項の連絡のため</p>
            <p>3.当社は、以下の通り会員の個人情報を共同利用いたします。</p>
            <p>(1)共同利用者の範囲</p>
            <p>・フェスタリアホールディングス株式会社およびフェスタリアホールディングス株式会社の連結子会社ならびに持分法適用関連会社</p>
            <p>・百貨店・商業施設など、会員が購入した商品の販売店舗を営業する事業者</p>
            <p>(2)共同利用する個人データの項目</p>
            <p>・当社グループにおいては、本条第1項に記載の会員登録時に提供された個人データ、会員登録日、対象店舗での購入履歴、およびお問い合わせの履歴、</p>
            <p>・販売店舗を営業する事業者においては、本条第1項に記載の個人情報のうち、(1)～(8)、会員登録日、対象店舗での購入履歴、およびお問い合わせの履歴</p>
            <p>(3)共同して利用する者の利用目的</p>
            <p>・当社グループにおいては、本条第2項に記載の個人情報の利用目的に同じ</p>
            <p>・対象店舗を営業する事業者においては、本条第2項に記載の利用目的のうち、(1)～(4)、(8)のためのみ</p>
            <p>4.会員は、当社に対して、当該個人情報の提供後、氏名・住所・お電話番号等について個人情報を開示するよう求めることができるものとします。開示の結果、当該個人情報に誤りがある場合は、会員は当社に対して当該個人情報の訂正または削除を要求することができます。開示、訂正または削除を要求される場合は、当該ご請求がご本人であることを確認したうえで、合理的な期間内で対応するものとします。</p>
            <p>5.当社は、上記に定めるほか、｢プライバシーポリシー｣に従って個人情報を適切に取扱い、フェスタリアホールディングスグループにおける個人情報保護に関する統括管理者によって個人情報の保護を行っています。</p>
            <p>6.本規約および会員登録情報に関するお問い合わせは、下記までお願いいたします。</p>
            <p>【お問い合わせ先】</p>
            <p>フェスタリアホールディングス株式会社</p>
            <p>電話番号　03-6633-6869</p>
            <p>受付時間　平日10:00～19:00（年末・年始および祝日・振替休日・国民の休日を除く）</p>
            <p></p>
            <p>第9条(規約の変更)</p>
            <p>当社は、会員への事前通知・承諾なしに本規約を随時変更することができるものとします。この場合、当社は会員に対して本サイト上で通知するものとし、改定の効力は、本サイト上への掲示時点で生じるものとします。</p>
            <p></p>
            <p>第10条(退会)</p>
            <p>1.会員は随時退会できるものとし、会員専用ページにて退会手続きを行うものとします。当社は、会員が本規約に同意できない旨の意思表示をした場合、および会員が第11条第1項に定める禁止事項に該当する場合には、当社の判断で、会員に通知することなく会員を退会させることができるものとします。</p>
            <p>2.退会手続完了後は、会員は会員特典を利用することができず、また会員特典に関わる一切の権利を失うことをあらかじめ了承するものとし、退会手続きを終了する前に、注意事項等を確認の上で退会するものとします。退会により発生した損害、不利益に関して当社は一切責任を負わないものとします。</p>
            <p></p>
            <p>第11条(禁止事項)</p>
            <p>1. 当社は、会員が会員特典を利用するにあたり、以下の各号に該当する行為を行うことを禁止します。なお、以下の各号の該当性の判断は当社の判断により行われるものとします。</p>
            <p>(1) 虚偽の氏名、性別、生年月日または自己のものではない電子メールアドレス、住所、電話番号等を用いて登録または登録内容を変更する行為</p>
            <p>(2) 電子メールアドレスおよびパスワードを公開し、または不正に利用する行為</p>
            <p>(3) 公序良俗に反する行為、法令等に違反する行為</p>
            <p>(4) 会員特典の運営を妨げ、その他当社が提供するサービスに支障をきたすおそれのある行為</p>
            <p>(5) その他当社が会員の行為として不適切であると認めた行為</p>
            <p>2.当社は、会員による会員特典の利用が前項各号のいずれかに該当すると当社が判断する場合、会員による会員特典の利用を制限、拒絶、あるいは契約の解除ができるものとします。この場合、会員が受ける不利益や損害につき当社は一切の責任を負わないものとします。</p>
            <p></p>
            <p>第12条(著作権その他知的財産権)</p>
            <p>1.本サイトに掲載された全ての内容に関する権利は、当社に帰属する権原にもとづき使用するものです。当社は、会員が本サイト等の掲載内容全部、また、会員からの問合せに対する当社からの回答等を、自らの個人利用目的以外に利用したり、ネットワークの内外を問わず、公衆に再提供したり、無断使用・複製することを禁止します。</p>
            <p>2.本サイト等に現れる全ての商標およびサービスマークは、当社の所有または、ライセンスその他の正当な権原にもとづき使用するものであり、当社は、会員がこれらを無断で使用することを禁止します。</p>
            <p>3.会員が提供した情報等について著作権(著作権法第 27 条および第 28 条に定める権利を含む)その他知的財産権が生じる場合には、会員は情報等にかかる権利を当社に無償で譲渡するものとし、著作者人格権等の権利を行使しないものとします。</p>
            <p></p>
            <p>第13条(損害賠償)</p>
            <p>1.会員は、会員特典の利用とその結果について責任を負い、会員特典の利用に関して自己の責に帰すべき事由により他の利用者または第三者に損害を与えた場合、自己の責任と費用をもってこれを解決するものとします。</p>
            <p>2.会員は、会員特典の利用に関して自己の責に帰すべき事由により当社に損害を与えた場合、当社が被った損害を賠償するものとします。</p>
            <p></p>
            <p>第14条(免責)</p>
            <p>1.当社は、会員特典の利用または会員特典により提供される情報等の利用により発生した損害に対し、いかなる責任も負わないものとし、一切の損害につき賠償する義務はないものとします。</p>
            <p>2.通信回線やコンピュータ等の障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた改ざん・損害、その他会員特典に関して会員に生じた損害について、当社は一切責任を負わないものとします。</p>
            <p>3.会員特典の利用による、会員同士、会員と会員特典における情報等提供者もしくは会員と第三者との間で生じた紛議について、当社は一切責任を負わないものとします。</p>
            <p>4.当社は会員特典の廃止について、一切責任を負わないものとします。</p>
            <p>5.会員特典において、利用されたログイン用 ID(電子メールアドレス)とパスワードの組み合わせが一致することを所定の方法により確認することにより、当社が会員本人による利用があったものとみなしたときは、それらが盗用、不正使用その他の事情により会員本人以外の第三者が利用している場合であっても、それにより生じた損害について、当社は一切責任を負わないものとします。</p>
            <p>6.会員の登録情報に変更が生じた場合に変更登録がなされなかったことにより生じた損害について、当社は一切責任を負わないものとします。</p>
            <p>7.本条の規定は、当社に故意もしくは重過失がある場合には適用しないものとします。何らかの理由により当社が責任を負う場合であっても、当社は、会員の損害につき、過去12か月間に会員が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。</p>
            <p></p>
            <p>第15条(会員特典の保守)</p>
            <p>当社は、会員特典の稼動状態を良好に保つために、以下の各号の場合、会員に事前に通知を行うことなく、会員特典の提供の全部または一部を中止することができるものとします。</p>
            <p>なお、当社はかかるサービスの中止により、会員に発生した損害、不利益に関して一切責任を負わないものとします。</p>
            <p>(1) システムの定期保守および緊急保守の場合</p>
            <p>(2) 火災、停電、第三者による妨害行為等により、システムの運用が困難になった場合</p>
            <p>(3) その他、止むを得ずシステムの停止が必要と当社が判断した場合</p>
            <p></p>
            <p>第16条(Cookieの利用)</p>
            <p>1.本サイトにおいて、以下に掲げる目的のために、一部のコンテンツで Cookie(クッキー)を利用するものとします。</p>
            <p>本サイトにおける Cookie の機能とは、お客さまが本サイトをご覧になったという情報を、そのお客さまのコンピュータ内に記憶させておく機能のことであり、当該Cookieを通じて収集する情報には、電子メールアドレスや氏名等の｢個人を特定できる情報｣は一切含まれません。</p>
            <p>(1) 本サイトに複数のページにまたがるアプリケーションがある場合に、より便利に本サイトをご覧いただくために必要な情報を一時的に保管する目的</p>
            <p>(2) お客さまが認証サービスにログインされているとき、保存されているお客さまの登録情報を参照して、お客さまごとにカスタマイズされたサービスを提供できるようにする目的</p>
            <p>(3) 本サイトを通じてより良いサービスを提供していくために、当社が本サイト上のお客さまのアクセス傾向を分析する目的</p>
            <p>(4) お客さまが興味を持っている内容や、本サイト上での利用状況をもとに、最も適切な広告を他社ウェブサイト上で表示する目的</p>
            <p>(5) セキュリティー保持のため、ご利用から一定の時間が経過したお客さまに対してパスワードの再入力(再認証)を促す目的</p>
            <p>2.お客さまが会員特典を適切に利用するためには、前項を承諾し、Cookie を受け付けることが条件となります。</p>
            <p>お客さまはブラウザで Cookie を拒否するための設定を行うこともできますが、その場合、一部が適切に機能しなくなったり、使えなくなったりするコンテンツが含まれることを予め承諾するものとします。</p>
            <p></p>
            <p>第17条(準拠法及び管轄裁判所)</p>
            <p>本サイトならびに本規約の解釈および適用は、日本国法に準拠します。</p>
            <p>また、本サイトおよび会員特典に関わるすべての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
            <p></p>
            <p>以上</p>
            <p></p>
            <p>制定日　</p>
            <p>２０２３年２月１日</p>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Agreement;
