import React from "react";
import "./style.scss";
const ErrorTop = ({message}) => {
  return (
    <div className="error-top">
      {message}
    </div>
  );
}

export default ErrorTop;
