import { useEffect } from "react";
import { getConfig } from "../config";
import axios from "axios";

const useYappliScript = (customerId) => {
  useEffect(() => {
    const getAuthConfig = async () => {
      try {
        const auth_data = await getConfig();
        if (!auth_data.configJson.useYappli) {
          return;
        }
        const script = document.createElement("script");
        const url = "https://yappli.io/v1/sdk.js";
        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        const script2 = document.createElement("script");

        if (customerId) {
          script2.innerHTML = `
            function sendId (id) {
              Yappli.sendRegisteredId(id, function (result, message) {
                if (!result) {
                  sendId (id);
                }
              });
            }
            sendId("${customerId}");
          `;
          script.async = true;

          // // add point to festaria api
          // const festariaApi = auth_data.configJson.festariaApi;
          // if (festariaApi) {
          //   await axios.post(festariaApi + '/Point/AddYappliPoint', {
          //     userId: customerId
          //   });
          // }
        }

        document.body.appendChild(script2);

        return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        };
      } catch (error) {
        console.log(error);
      }
    };
    getAuthConfig();
  }, [customerId]);
};

export default useYappliScript;
