import React, { useState, useEffect } from "react";
import Animate from "components/animate/animate";
import { useTranslation } from "react-i18next";
import { api_config } from "utils/APIConfig";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import "./style.scss";

const Security = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const activities = [
    { id: 1, action: t("security_page.lbl_login"), apps: t("security_page.fdcp_desktop"), date: "12/12/2020" },
    { id: 2, action: t("security_page.lbl_password"), apps: t("security_page.mobile"), date: "13/11/2020" },
  ];

  const verification = [
    { id: 1, devices: t("security_page.lbl_email"), registered: "", status: false },
    { id: 2, devices: t("security_page.lbl_phone"), registered: "090-0000-00000", status: true },
  ];

  const [profile, setProfile] = useState();

  useEffect(() => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      setProfile(userinfo);
    }
  }, []);

  const setupOTP = async () => {
    const url_otp = "https://mypage-service.dev.upbond.io/enable-otp";
    const data = {
      enable_otp: !profile.enable_otp,
      phone: "+6285749724470",
    };

    axios
      .patch(url_otp, data, api_config.config)
      .then((response) => {
        console.log(response.data);
        if (!profile.enable_otp) {
          history.push(`/otp/send/${response.data.data.phone}`);
        } else {
          let newUserInfo = { ...profile, enable_otp: !profile.enable_otp };
          localStorage.setItem("userinfo", JSON.stringify(newUserInfo));
          setProfile(newUserInfo);
          SuccessConfirm();
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const SuccessConfirm = () => {
    Swal.fire({
      title: "Success!",
      text: "Your OTP has been disabled",
      icon: "success",
      confirmButtonText: "Close",
      confirmButtonColor: "#199f19",
    });
  };

  return (
    <Animate>
      <div className="security-page">
        <div className="card password">
          <div className="card-title">
            <h1>{t("security_page.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className="section activity">
              <div className="title">{t("security_page.recent_activity")}</div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>{t("security_page.th_action")}</th>
                    <th>{t("security_page.th_application")}</th>
                    <th>{t("security_page.th_date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {activities.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="action">{item.action}</td>
                        <td className="application">{item.apps}</td>
                        <td className="date">{item.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="section verification">
              <div className="title">{t("security_page.verification")}</div>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>{t("security_page.th_devices")}</th>
                    <th></th>
                    <th>{t("security_page.th_status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {verification.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="devices">{item.devices}</td>
                        <td className="registered">
                          {item.registered ? item.registered : t("security_page.unregistered")}
                          <i className="fas fa-pen"></i>
                        </td>
                        <td className="status">
                          <i className={`fas fa-check-circle ${item.status ? "active" : ""}`}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="section setting-2FA">
              <div className="title">2 Factor Authentication (2FA)</div>
              <button
                className={"btn btn-verify " + (profile && profile.enable_otp ? "disable" : "enable")}
                onClick={setupOTP}
              >
                {profile && profile.enable_otp ? "Disable 2FA" : "Enable 2FA"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Security;
