export const getAnyOfValue = (id, list) => {
  const { anyOf } = list
  if(anyOf) {
    const filtered = anyOf.filter(obj => {
      return obj.enum.includes(id)
    })
    if(filtered.length > 0) {
      const { title } = filtered[0]
      return title
    }
  }

  return null
}