import React from "react";
import { motion } from "framer-motion";

const Animate = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        ease: "anticipate",
        duration: 0.5,
      }}
    >
      {children}
    </motion.div>
  );
};

export default Animate;
