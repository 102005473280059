import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Profile from "./profile";
import ProfileEdit from "./edit";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { isFestariaAcct } from '../../utils/Ecommerce'
import CreateAccount from "./create";
import Withdraw from "./withdraw";

const ProfileRouter = ({match}) => {
  const authConf = useContext(AuthConfigContext);
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Profile}/>
      <Route exact path={`${match.url}/edit`} component={ProfileEdit}/>
      {isFestariaAcct(authConf?.configJson?.account) &&
        <>
          <Route exact path={`${match.url}/create`} component={CreateAccount}/>
          <Route exact path={`${match.url}/withdraw`} component={Withdraw}/>
        </>
      }
    </Switch>
  )
}

export default ProfileRouter;