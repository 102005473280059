import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const BackHome = () => {
  return (
    <div className="action-area">
      <div className="back-home">
        <Link to="/home">
          <button type="button" className="btn btn-back">
            <i className="fas fa-home"></i>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BackHome;
