import { points_config, queryParam } from "utils/APIConfig";
import { ACTION_GET, ACTION_POST, ACTION_PUT } from "utils/AxiosActions";

export const Get_Warranties = async (userId, page, number) => {
    const _conf = await points_config();
    const url = `${_conf.config.endpoints.warranty_url}?${queryParam({
        userId: userId,
        page: page,
        size: number
    })}`;

    console.log('get warranty payload...');
    console.log(userId, page, number);

    return new Promise((resolve, reject) => {
        ACTION_GET(url, _conf.config)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}