import React from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import TicketDetails from "../components/TicketDetails";
import TicketInformation from "../components/TicketInformation";
import TicketTop from "../components/TicketTop";
import { Link } from "react-router-dom";

export const Ticket = ({id, price, owner, event, is_over, date_issued, is_verified}) => {

  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const formatDate = (date) => {
    const d = new Date(date);
    // const formatted = `${d.getDate()} ${months[d.getMonth()]}`;
    const formatted = `${d.getMonth() + 1}月${d.getDate()}日`;
    return formatted;
  }

  const formatTime = (date) => {
    const d = new Date(date);
    const formatted = `${d.getUTCHours()}:${d.getMinutes()}`;
    return formatted;
  }

  const formatSeating = (seatObj) => {
    const formatted = `${seatObj.aisle}列 ${seatObj.seats.join(' & ')}番`
    return formatted;
  }

  return (
    <div className="ticket-custom">
      <Container style={{padding: "0px"}}>
        <Card
          className="ticket-cover-img"
          style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${event.cover_photo})`}}
        >
          <p
            className="w-100 d-flex flex-column fs-5 fw-bold text-white"
            style={{position: "absolute", bottom: "17%", left: "50%", transform: "translate(-50%, 0)"}}
          >
            <span>
              <span>{event.name}</span>
            </span>
            <span>
              <i className="fas fa-map-marker-alt px-1"></i>
              <span>{event.location.venue}</span>
            </span>
          </p>
        </Card>
        <Card className="ticket-card d-flex flex-column align-items-center gap-4">

          <TicketTop event={event}/>
          <TicketDetails event={event} owner={owner} is_verified={is_verified} formatDate={formatDate} formatTime={formatTime} formatSeating={formatSeating}/>
          <TicketInformation event={event} price={price}/>

        </Card>
      </Container>
      <div className="d-flex">
        <Link to="/tickets">
          <button type="button" className="bg-white rounded-circle border-0 px-2 mt-2">
            <i className="fas fa-chevron-left"></i>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Ticket;