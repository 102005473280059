import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

export const PageTitle = ({title}) => {
  return (
    <Row>
      <Col className="d-flex w-25">
        <h1 className="title-custom fs-3 pb-3">{title}</h1>
      </Col>
    </Row>
  )
}

export default PageTitle;