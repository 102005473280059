import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "pages/home";
// import ToggleLang from "components/language-toggle";
import Page404 from "pages/error/404";
import "./style.scss";
import { AuthConfigContext } from "context/AuthConfigProvider";
import ProfileRouter from "pages/profile/ProfileRouter"
import SocialConnections from "pages/social_connections";
import DataPrivacy from "pages/data_privacy";
import ReservationRouter from "pages/reservation/ReservationRouter";
import Security from "pages/security";
import Wallet from "pages/wallet";
import NFT from "pages/nft";
import TicketRouter from "pages/ticket/TicketRouter";
import OTP_Send from "pages/otp/OTP_send";
import Purchase from "pages/nft/purchase";
import PointsRouter from "pages/points/PointsRouter";
import Warranty from "pages/warranty";
import OrderHistory from "pages/order";
import Agreement from "pages/agreement";
import EcommerceRouter from "pages/ecommerce/EcommerceRouter";

const PageContent = () => {
  const authConfig = useContext(AuthConfigContext);
  const services = authConfig.configJson.services;
  return (
    <div className="main-content">
      {/* <ToggleLang /> */}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        {services && services.map((route, i) => {
          switch (route.name) {
            case "Profile":
              return (
                <Route path={route.link} component={ProfileRouter} key={i} />
              )
            case "Social Connections":
              return (
                <Route path={route.link} component={SocialConnections} key={i} />
              )
            case "Data & Privacy":
              return (
                <Route path={route.link} component={DataPrivacy} key={i} />
              )
            case "Security":
              return (
                <Route path={route.link} component={Security} key={i} />
              )
            case "Reservation":
              return (
                <Route path={route.link} component={ReservationRouter} key={i} />
              )
            case "Wallet":
              return (
                <Route path={route.link} component={Wallet} key={i} />
              )
            case "NFT":
              return (
                <Route path={route.link} component={NFT} key={i} />
              )
            case "Ticket":
              return (
                <Route path={route.link} component={TicketRouter} key={i} />
              )
            case "OTP_Send":
              return (
                <Route path={route.link} component={OTP_Send} key={i} />
              )
            case "Purchase":
              return (
                <Route path={route.link} component={Purchase} key={i} />
              )
            case "Points":
              return (
                <Route path={route.link} component={PointsRouter} key={i} />
              )
            case "Warranty":
              return (
                <Route path={route.link} component={Warranty} key={i} />
              )
            case "Ecommerce":
              return (
                <Route path={route.link} component={EcommerceRouter} key={i} />
              )
            case "Order":
              return (
                <Route path={route.link} component={OrderHistory} key={i} />
              )
            case "Agreement":
              return (
                <Route path={route.link} component={Agreement} key={i} />
              )
            default :
            return (
              <Route exact path="/home" component={Home} />
            )
          }
        })}
        <Route path={'/ecommerce'} component={EcommerceRouter} />
        <Route component={Page404} />
      </Switch>
    </div>
  );
};

export default PageContent;
