import React from "react";
import { useHistory } from "react-router-dom";
import NotFound from "assets/misc/404.svg";
import Animate from "components/animate/animate";
import "./style.scss";

const Page404 = () => {
  const history = useHistory();

  return (
    <Animate>
      <div className="page-not-found">
        <div className="card">
          <div className="card-body">
            <img src={NotFound} alt="not-found" />
            <h1>Page Not Found</h1>
            <div className="back" onClick={() => history.goBack()}>
              back to previous page
            </div>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Page404;
