import moment from "moment";

import DragonBall from "assets/image/nft-mock/apps/dragonball.png";
import Gundam from "assets/image/nft-mock/apps/gundam.png";
import Pokemon from "assets/image/nft-mock/apps/pokemon.png";
import OnePiece from "assets/image/nft-mock/apps/onepiece.png";

import goku1 from "assets/image/nft-mock/items/dragonball/goku1.png";
import goku2 from "assets/image/nft-mock/items/dragonball/goku2.png";
import goku3 from "assets/image/nft-mock/items/dragonball/goku3.png";
import beerus from "assets/image/nft-mock/items/dragonball/beerus.png";
import gotenks from "assets/image/nft-mock/items/dragonball/gotenks.png";
import trunks from "assets/image/nft-mock/items/dragonball/trunks.png";

import luffy from "assets/image/nft-mock/items/onepiece/luffy.png";
import ace from "assets/image/nft-mock/items/onepiece/ace.png";
import brook from "assets/image/nft-mock/items/onepiece/brook.png";
import usop from "assets/image/nft-mock/items/onepiece/usop.png";
import jinbei from "assets/image/nft-mock/items/onepiece/jinbei.png";
import sanji from "assets/image/nft-mock/items/onepiece/sanji.png";
import zoro from "assets/image/nft-mock/items/onepiece/zoro.png";
import roger from "assets/image/nft-mock/items/onepiece/roger.png";

import Gundam1 from "assets/image/nft-mock/items/gundam/gundam-1.png";
import Gundam2 from "assets/image/nft-mock/items/gundam/gundam-2.png";
import Gundam3 from "assets/image/nft-mock/items/gundam/gundam-3.png";
import Gundam4 from "assets/image/nft-mock/items/gundam/gundam-4.png";
import Gundam5 from "assets/image/nft-mock/items/gundam/gundam-5.png";

import Pokemon1 from "assets/image/nft-mock/items/pokemon/pokemon-1.png";
import Pokemon2 from "assets/image/nft-mock/items/pokemon/pokemon-2.png";
import Pokemon3 from "assets/image/nft-mock/items/pokemon/pokemon-3.png";
import Pokemon4 from "assets/image/nft-mock/items/pokemon/pokemon-4.png";
import Pokemon5 from "assets/image/nft-mock/items/pokemon/pokemon-5.png";

export const appsItem = [
  { id: 1, apps: "gundam", name: "Gundam", image: `${Gundam}` },
  { id: 2, apps: "dragonball", name: "Dragon Ball", image: `${DragonBall}` },
  { id: 3, apps: "pokemon", name: "Pokemon", image: `${Pokemon}` },
  { id: 4, apps: "onepiece", name: "One Piece", image: `${OnePiece}` },
];

export const allCardsItem = [
  { id: 5575, apps: "dragonball", name: "Son Goku - SSJ", image: `${goku1}` },
  { id: 5121, apps: "dragonball", name: "Son Goku Base", image: `${goku2}` },
  {
    id: 4364,
    apps: "dragonball",
    name: "Super Gotenks - SSJ 3",
    image: `${gotenks}`,
  },
  { id: 3332, apps: "dragonball", name: "Beerus", image: `${beerus}` },
  { id: 4122, apps: "dragonball", name: "Son Goku - SSJ 3", image: `${goku3}` },
  { id: 7753, apps: "dragonball", name: "Trunks", image: `${trunks}` },

  { id: 1241, apps: "onepiece", name: "Monkey D Luffy", image: `${luffy}` },
  { id: 3526, apps: "onepiece", name: "Portgas D Ace", image: `${ace}` },
  { id: 1263, apps: "onepiece", name: "Usop", image: `${usop}` },
  { id: 1268, apps: "onepiece", name: "Gol D Roger", image: `${roger}` },
  { id: 6322, apps: "onepiece", name: "Jinbei Oyabun", image: `${jinbei}` },
  { id: 7433, apps: "onepiece", name: "Vinsmoke Sanji", image: `${sanji}` },
  { id: 8655, apps: "onepiece", name: "Shimotsuki Zoro", image: `${zoro}` },
  { id: 4326, apps: "onepiece", name: "Brook", image: `${brook}` },

  { id: 1266, apps: "gundam", name: "Gundam - 1", image: `${Gundam1}` },
  { id: 1477, apps: "gundam", name: "Gundam - 2", image: `${Gundam2}` },
  { id: 3236, apps: "gundam", name: "Gundam - 3", image: `${Gundam3}` },
  { id: 7890, apps: "gundam", name: "Gundam - 4", image: `${Gundam4}` },
  { id: 4346, apps: "gundam", name: "Gundam - 5", image: `${Gundam5}` },

  { id: 7653, apps: "pokemon", name: "Pokemon - 1", image: `${Pokemon1}` },
  { id: 4432, apps: "pokemon", name: "Pokemon - 2", image: `${Pokemon2}` },
  { id: 5511, apps: "pokemon", name: "Pokemon - 3", image: `${Pokemon3}` },
  { id: 5677, apps: "pokemon", name: "Pokemon - 4", image: `${Pokemon4}` },
  { id: 9987, apps: "pokemon", name: "Pokemon - 5", image: `${Pokemon5}` },
];

export const DetailsItem = (data) => {
  // const nftID = data.id.split("-")[0];
  const nftID = data.id;
  const createdDate = moment(data.created_at).format("DD MMMM YYYY");

  let descStat = "";
  if (!data.description) {
    descStat = "empty";
    data.description = "No description . . .";
  }
  console.log(data);

  const htmlContent = `<div class="item-details">
  <div class="title">${data.name}</div>
  <div class="divider"></div>
  <div class="image">
    <img src=${data.image} alt="img-item" />
  </div>
  <div class="divider"></div>
  <div class="description ${descStat}">${data.description}</div>
  <div class="divider"></div>
  <div class="details">
    <ul>
      <li class="SN">
        <div class="label">Serial Number</div>
        <div class="value">${nftID}</div>
      </li>
      <li class="date">
        <div class="label">Created Date</div>
        <div class="value">${createdDate}</div>
      </li>
      <li class="supply">
        <div class="label">Supply</div>
        <div class="value">${data.supply}</div>
      </li>
      <li class="tokenID">
        <div class="label">Token ID</div>
        <div class="value">${data.tokenId}</div>
      </li>
    </ul>
  </div>`;

  return htmlContent;
};
