import React, { useState } from "react";
import eyeIcon from "assets/misc/eye.svg";
import eyeSlashIcon from "assets/misc/eye-slash.svg";
import "./style.scss";

const PasswordField = ({ id, value, onChange, schema }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordValidation = {
    ...schema,
    type: "string",
  };

  return (
    <div className="form-group field field-string">
      <label className="control-label" for="root_email">
        {schema.title}
        <span className="required">*</span>
      </label>
      <div className="icon-div">
        <input
          className="form-control"
          id={id}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <i onClick={togglePasswordVisibility}>
          {showPassword ? (
            <img src={eyeIcon} alt="password-show" height={"30px"} />
          ) : (
            <img src={eyeSlashIcon} alt="password-hidden" height={"30px"} />
          )}
        </i>
      </div>
    </div>
  );
};

export default PasswordField;
