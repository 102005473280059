import { api_config, queryParam } from "utils/APIConfig";
import { ACTION_GET } from "utils/AxiosActions";

export const GET_NFT_List = async () => {
  const auth = await api_config();
  console.log("auth", auth);
  const params = {
    size: 5,
    account: auth.account,
    aud: auth.aud,
    iss: auth.iss,
  };
  const url = `${auth.wallet_host}/owned?${queryParam(params)}/`;
  const result = await ACTION_GET(url);
  if (result) {
    console.log(result);
    return result;
  }
};

export const GET_NFT_Details = async (itemID) => {
  const params = {
    account: api_config.account,
    aud: api_config.aud,
    iss: api_config.iss,
  };
  const url = `${api_config.wallet_host}/integration-detail/${itemID}?${queryParam(params)}/`;
  const result = await ACTION_GET(url);
  if (result) {
    return result;
  }
};
