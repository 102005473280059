import React, { useState, useEffect } from "react";
import Animate from "components/animate/animate";
import { useLocation } from "react-router-dom";
import { GET_NFT_Details } from "services/NFT";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./style.scss";
import StripeContainer from "components/stripe-payment/StripeContainer";

const MySwal = withReactContent(Swal);

const Purchase = () => {
  const location = useLocation();
  const [NFT, setNFT] = useState();
  const [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
    const id = location.pathname.split("/").pop();
    GET_NFT_Details(id)
      .then((result) => {
        console.log(result.data);
        setNFT(result.data);
      })
      .catch((error) => {
        if (error.status === 403) {
          window.location.reload();
        }
      });

    if (!showPayment) {
      MySwal.close();
    }
  }, [location, showPayment]);

  const onCancel = () => {
    setShowPayment(false);
  };

  const purchase = () => {
    setShowPayment(true);
    MySwal.fire({
      html: <StripeContainer onCancelClick={() => onCancel()} NFTID={NFT.detail._id} />,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  };

  const NFTCard = () => {
    if (NFT) {
      return (
        <div className="card nft-card">
          <div className="head-area">
            <div className="title">{NFT.content.name}</div>
            <img src={NFT.content.image} alt="nft-pic" />
          </div>
          <div className="card-body">
            <p>{NFT.content.description}</p>
            <div className="divider"></div>
            <ul className="list-description">
              <li>
                <div className="label">Category</div>
                <div className="value">{NFT.content.attributes.category}</div>
              </li>
              <li>
                <div className="label">Type</div>
                <div className="value">{NFT.content.attributes.type}</div>
              </li>
              <li>
                <div className="label">Price</div>
                <div className="value">
                  {NFT.price} {NFT.currency}
                </div>
              </li>
              <li>
                <div className="label">Supply</div>
                <div className="value">{NFT.detail.mint_data.supply}</div>
              </li>
              <li>
                <div className="label">Token ID</div>
                <div className="value">{NFT.detail.mint_data.tokenId}</div>
              </li>
              <li>
                <div className="label">Contract Address</div>
                <div className="value contract">{NFT.detail.mint_data.contractAddress}</div>
              </li>
            </ul>
            <div className="divider"></div>
            <div className="nft-action-area">
              <button className="btn btn-submit" onClick={purchase}>
                Purchase
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="loading spinner-border"></div>;
    }
  };

  return (
    <Animate>
      <div className="purchase-page">
        <div className="card">
          <div className="card-title">
            <h2>Purchase NFT</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <NFTCard />
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Purchase;
