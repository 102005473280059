import React from "react";
import { Switch, Route } from "react-router-dom";
import Tickets from "./tickets";
import Ticket from "./ticket";
import { ticketsUpcoming, ticketsPast } from "./ticket-data";

const TicketRouter = ({match}) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Tickets}/>
      {ticketsUpcoming.map((upcoming) => (
        <Route
          exact
          path={`${match.url}/${upcoming.id}`}
          key={upcoming.id}
          render={(props) => <Ticket details={props} {...upcoming} />}
        />
      ))}
      {ticketsPast.map((past) => (
        <Route
          exact
          path={`${match.url}/${past.id}`}
          key={past.id}
          render={(props) => <Ticket details={props} {...past} />}
        />
      ))}
    </Switch>
  )
}

export default TicketRouter;