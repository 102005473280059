import React from "react";
import Animate from "components/animate/animate";
import BackHome from "components/backhome-button";
import { ccDataForm, walletDataForm } from "./htmlContent";
import Swal from "sweetalert2";
import "./style.scss";

const Wallet = () => {
  const registerCcPopup = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Register Credit Card",
      html: ccDataForm(),
      confirmButtonText: 'Register',
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        const name = document.getElementById("cc-name");
        const creditcard = document.getElementById("cc-creditcard");
        const month = document.getElementById("cc-valid-mm");
        const year = document.getElementById("cc-valid-yy");
        const security = document.getElementById("cc-security");

        if (name.value && creditcard.value && month.value && year.value && security.value) {
          return {
            name: name.value,
            creditcard: creditcard.value,
            month: month.value,
            year: year.value,
            security: security.value,
          };
        } else {
          Swal.showValidationMessage(`Complete all the form inputs, please!`);
        }
      },
    });

    if (formValues) {
      console.log(formValues);
      // Swal.fire(JSON.stringify(formValues));
    }
  };

  const registerWalletPopup = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Register Wallet",
      html: walletDataForm(),
      confirmButtonText: 'Register',
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        const name = document.getElementById("wallet-name");
        const number = document.getElementById("wallet-number");
        const security = document.getElementById("wallet-security");

        if (name.value && number.value && security.value) {
          return {
            name: name.value,
            number: number.value,
            security: security.value,
          };
        } else {
          Swal.showValidationMessage(`Complete all the form inputs, please!`);
        }
      },
    });

    if (formValues) {
      console.log(formValues);
      // Swal.fire(JSON.stringify(formValues));
    }
  }
  return (
    <Animate>
      <div className="wallet-page">
        <div className="card token">
          <div className="card-title">
            <h2>Tokens</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <ul className="list-group">
              <li className="list-group-item solana">
                <div className="label">Solana</div>
                <div className="value">
                  DYw8jCTfwHNRJhhmFcbXvVDTqWMEVFBX6ZKUmG5CNSKK
                </div>
              </li>
              <li className="list-group-item ethereum">
                <div className="label">Ethereum</div>
                <div className="value">
                  0x347B9DcE27347B5B33D71E114ea8cd911a3763aB
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card connect">
          <div className="card-title">
            <h2>Wallets</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <button className="btn btn-action" onClick={() => registerWalletPopup()}>Create Wallet</button>
          </div>
        </div>
        <div className="card credit-card">
          <div className="card-title">
            <h2>Credit Cards</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <p>Credit Card</p>
            <button className="btn btn-action" onClick={() => registerCcPopup()}>
              Register
            </button>
          </div>
        </div>
        <BackHome />
      </div>
    </Animate>
  );
};

export default Wallet;
