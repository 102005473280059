import React from "react";
import Row from 'react-bootstrap/Row';
import QRCode from "react-qr-code";

export const TicketTop = ({event}) => {
  return (
    <>
      <Row className="w-25 mt-4 rounded-3" style={{borderBottom: "6px solid gray"}}></Row>
      <Row>
        <QRCode value={event.id}/>
      </Row>
      <Row className="bg-black fw-bold text-white px-5 py-1 rounded-3">
        {event.event_type}
      </Row>
      <Row className="w-75"><hr/></Row>
    </>
  )
}

export default TicketTop;