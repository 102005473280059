import React, { useEffect, useRef } from "react";
import UsePointsBtn from "components/points/use-points-btn";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Popup = ({closePopup, action = null, title = null, description = null , disabled = false ,bg="#001233"}) => {
  const popupRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (closePopup && popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup()
      }
    }
    document.addEventListener("mousedown", handleOutsideClick)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [popupRef, closePopup])

  return (
    <div
      className="popup"
      style={{ background: "rgba(0,0,0,0.5)" }}>
      <div ref={popupRef} className="popup-inner">
        <div className="title">
          {title ? (
            <h1>{title}</h1>
          ) : (
            null
          )}
        </div>
        <div className="description">
          {description ? (
            <p className="description">{description}</p>
          ) : (
            null
          )}
        </div>

        <div className="bottom-actions">
          <UsePointsBtn text={t("points_page.finalize")} isLink={false} callback={action} bg={bg} disabled={disabled}/>
          <button className="cancel-btn" onClick={() => closePopup()}>
            {t('points_page.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Popup;
