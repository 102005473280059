import React, { useState, useEffect, useRef } from "react";
import Animate from "components/animate/animate";
import axios from "axios";
import { api_config } from "utils/APIConfig";
import useInterval from "./useInterval";
import Swal from "sweetalert2";
import "./style.scss";

const OTP_Send = () => {
  const sendRef = useRef(null);
  const verifyRef = useRef(null);

  const [count, setCount] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const [step, setStep] = useState("confirm");
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");

  useInterval(() => setCount(count - 1), isPlaying ? 1000 : null);

  useEffect(() => {
    if (!isPlaying && sendRef.current) {
      sendRef.current.focus();
    } else {
      verifyRef.current.focus();
    }
  }, [OTP, count, phone, isPlaying]);

  const sendOTP = async () => {
    setOTP("");
    setCount(30);
    setStep("otp");
    setPlaying(true);
    setTimeout(() => {
      setPlaying(false);
    }, 1000 * 30);

    const url_otp = "https://sms-service.dev.upbond.io/send-otp?iss=https://lzg2dndj.auth.dev.upbond.io/";
    const data = {
      // phone: "+818056038705", // Topan Number
      phone: phone,
    };

    const response = await axios.post(url_otp, data, api_config.config);
    if (response) {
      console.log(response.data);
    }
  };

  const validateOTP = async () => {
    const url_otp = "https://sms-service.dev.upbond.io/validate-otp?iss=https://lzg2dndj.auth.dev.upbond.io/";
    const data = {
      code: OTP,
    };

    const response = await axios.post(url_otp, data, api_config.config);
    if (response) {
      console.log(response.data);
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      let newUserInfo = { ...userinfo, enable_otp: !userinfo.enable_otp };
      localStorage.setItem("userinfo", JSON.stringify(newUserInfo));

      SuccessConfirm();
    }
  };

  const SuccessConfirm = () => {
    setPlaying(false);

    Swal.fire({
      title: "Success!",
      text: "OTP has been activated and verified",
      icon: "success",
      confirmButtonText: "Close",
      confirmButtonColor: "#199f19",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // history.push("/security");
        window.location.href = "/security";
      }
    });
    return null;
  };

  /** --------------  OTP RENDER -------------- */

  const OTPValidate = () => {
    return (
      <div className="OTP-Validate">
        <div className="caption">
          <h2>OTP Verification</h2>
          <div>Enter the OTP sent to your number</div>
        </div>

        <div className="form-group form-validate-otp">
          <input
            ref={verifyRef}
            type="text"
            maxLength={6}
            name="code"
            placeholder="******"
            className="form-control"
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
            disabled={isPlaying ? false : true}
            onKeyPress={(e) => (!/[0-9+]/.test(e.key) ? e.preventDefault() : e.target.value)}
          />
          <div className="expired" style={{ display: isPlaying ? "flex" : "none" }}>
            <div className="desc">expired in</div>
            <span className="number">{count}</span>
          </div>
          <div className="resend" style={{ display: isPlaying ? "none" : "block" }}>
            Didn't receive a code?
            <span className="btn-resend" onClick={sendOTP}>
              Resend
            </span>
          </div>

          <button
            className="btn btn-submit"
            type="button"
            disabled={isPlaying && OTP.length === 6 ? false : true}
            onClick={validateOTP}
          >
            Verify
          </button>
        </div>
      </div>
    );
  };

  const OTPSend = () => {
    return (
      <div className="OTP-Send">
        <div className="title">Enter your mobile phone number to verify account</div>
        <div className="subtitle">We will send you one time password (OTP)</div>

        <div className="form-group form-send-otp">
          <input
            ref={sendRef}
            type="text"
            name="phone"
            className="form-control"
            placeholder="phone number"
            maxLength={15}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onKeyPress={(e) => (!/[0-9+]/.test(e.key) ? e.preventDefault() : e.target.value)}
          />
          <button className="btn btn-submit" type="button" onClick={sendOTP}>
            Send OTP
          </button>
        </div>
      </div>
    );
  };

  return (
    <Animate>
      <div className="otp-page">
        <div className="card password">
          <div className="card-title">
            <h2>Verify Account</h2>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">{step === "confirm" ? <OTPSend /> : <OTPValidate />}</div>
        </div>
      </div>
    </Animate>
  );
};

export default OTP_Send;
