import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom"

const TicketsDisplay = ({title, tickets}) => {
  const [windowLength, setWindowLength] = useState(0);

  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  let resizeWindow = () => {
    const windowLng = window.innerWidth
    setWindowLength(windowLng);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);


  const formatDate = (date) => {
    const d = new Date(date);
    // const formatted = `${d.getDate()} ${months[d.getMonth()]} @${d.getUTCHours()}:${d.getMinutes()}`;
    const formatted = `${d.getMonth() + 1}月${d.getDate()}日 @${d.getUTCHours()}:${d.getMinutes()}`;
    return formatted;
  }

  return (
    <>
      <Row className="d-flex justify-content-left flex-column pt-4">
        <Col className="d-flex">
          <h1 className="fs-4 pb-3">{title}</h1>
        </Col>
      </Row>
      <Row
        className="d-flex flex-row event-scroll gap-4"
        style={{flexWrap: windowLength < 1400 && windowLength > 769 ? 'wrap' : "nowrap", maxWidth: "1000px"}}
        xs={1} md={2} lg={2} xl={4}
      >
        {tickets.map((ticket) => (
          <Col key={ticket.id}>
            <Link className="link-custom" to={`tickets/${ticket.id}`}>
              <Card className="border-0">
                <Card.Img src={ticket.event.cover_photo}/>
                <Card.ImgOverlay>
                  <div className="category position-absolute top-1 start-2 text-white bg-black py-1 px-5">
                    {ticket.event.event_type}
                  </div>
                </Card.ImgOverlay>
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="d-flex fs-5 fw-bold">{ticket.event.name}</Card.Title>
                  <Card.Text className="d-flex flex-column align-items-start">
                    <span className="d-flex gap-2 align-items-baseline"> <i className="fas fa-map-marker-alt"></i>{ticket.event.location.venue} </span>
                    <span className="d-flex gap-2 align-items-baseline"> <i className="far fa-calendar-alt"></i>{formatDate(ticket.event.date)} </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default TicketsDisplay;