import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animate from "components/animate/animate";
import "./style.scss";

const SocialConnections = () => {
  const { t } = useTranslation();

  const connections = [
    { id: "1", name: "Gmail", connect: true },
    { id: "2", name: "SendGrid", connect: false },
    { id: "3", name: "LINE", connect: false },
  ];

  return (
    <Animate>
      <div className="social-connections-page">
        <div className="card">
          <div className="card-title">
            <h1>{t("socialConnection_page.title")}</h1>
            <Link className="edit" to="#">
              <i className="fas fa-pen"></i>
              {t("profile_page.btn_edit")}
            </Link>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item desc">{t("socialConnection_page.subtitle")}</li>
              {connections.map((item) => {
                return (
                  <li key={item.id} className="list-group-item">
                    <div className="name">{item.name}</div>
                    <div className="status">
                      {item.connect ? t("socialConnection_page.connected") : t("socialConnection_page.unconnected")}
                    </div>
                    <div className="btn-connect">
                      {!item.connect && (
                        <button className="btn btn-primary">{t("socialConnection_page.btn_connect")}</button>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="faq">
              <i className="fas fa-caret-right"></i>
              <a href="https://upbond.notion.site/UPBOND-Documentation-Home-6c36464a94c04b83a1ff4f5d7d117850">{t("socialConnection_page.faq_about")}</a>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default SocialConnections;
