import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Animate from "components/animate/animate";
import "./style.scss";

// import { Store_Info } from "../../services/Reservation";
import { Profile_Info, Profile_Update } from "services/Profile";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const Reservation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({ mode: "onBlur" });

  const [profileInfo, setProfileInfo] = useState({});
  const [emailSync, setEmailSync] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  /** ------------- form input values ------------ */
  const [name, setName] = useState("");
  const [furigana, setFurigana] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [comments, setComments] = useState("");


  /** ------------- ---------------- ------------ */

  useEffect(() => {
    const getProfile = async () => {
      const profile = await Profile_Info();
      if(profile) {
        setProfileInfo(profile.data)
        setName(profile.data.name)
        setFurigana(profile.data.furigana ? profile.data.furigana : "")
        setPhone(profile.data.phone ? profile.data.phone : "")
        setEmail(profile.data.email ? profile.data.email : "")
        setEmailConfirm(profile.data.email ? profile.data.email : "")
        setComments(profile.data.comments ? profile.data.comments : "")

        setValue("name", profile.data.name ? profile.data.name : "");
        setValue("furigana", profile.data.furigana ? profile.data.furigana : "");
        setValue("phone", profile.data.phone ? profile.data.phone : "");
        setValue("email", profile.data.email ? profile.data.email : "");
        setValue("emailConfirm", profile.data.email ? profile.data.email : "");
        setValue("comments", profile.data.comments ? profile.data.comments : "");
      }
    };
    getProfile();
    // getStores(1);
  }, [setValue]);


  // const getStores = async (id) => {
  //   const storeInfo = await Store_Info(id);
  //   if(storeInfo) {
  //     console.log('getStores', storeInfo);
  //   }
  // }

  const thisSubmit = async (data) => {
    console.log('profileInfo', profileInfo);
    console.log('data before profileInfo added', data);
    setEmailSync(true);
    setSubmitLoading(true);

    if (email !== emailConfirm) {
      setSubmitLoading(false);
      setEmailSync(false);
      return;
    }

    data = {
      ...data,
      comments
    }

    const submit = await Profile_Update(data);
    if(submit) {
      console.log(submit);
      history.push({
        pathname: '/reservation-confirm',
        state: submit
      })
    }
  }

  return (
    <Animate>
      <div className="reservation-page">
        <div className="card token">
          <div className="card-title">
            <h1>{t("reservation_page.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(thisSubmit)}>
              <div className="subtitle"><p>{t("reservation_page.subtitle")}</p></div>
              <div className="header">{t("reservation_page.customer_info")}</div>
              <div className="form-group name">
                <label className="form-label">
                  {t("reservation_page.lbl_name")}
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control name"
                  value={name}
                  {...register("name", {
                    required: t("reservation_page.vld_name_required"),
                    maxLength: {
                      value: 100,
                      message: t("reservation_page.vld_name_max")
                    },
                  })}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <div className="error">{errors.name.message}</div>}
              </div>
              <div className="form-group furigana">
                <label className="form-label">
                  {t("reservation_page.lbl_furigana")}
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="furigana"
                  className="form-control name"
                  value={furigana}
                  {...register("furigana", {
                    required: t("reservation_page.vld_furigana_required"),
                    maxLength: {
                      value: 100,
                      message: t("reservation_page.vld_furigana_max")
                    },
                  })}
                  onChange={(e) => setFurigana(e.target.value)}
                />
                {errors.furigana && <div className="error">{errors.furigana.message}</div>}
              </div>
              <div className="form-group phone">
                <label className="form-label">
                  {t("reservation_page.lbl_phone")}
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  className="form-control name"
                  value={phone}
                  {...register("phone", {
                    required: t("reservation_page.vld_phone_required"),
                    minLength: {
                      value: 10,
                      message: t("reservation_page.vld_phone_min")
                    },
                    maxLength: {
                      value: 36,
                      message: t("reservation_page.vld_phone_max")
                    },
                  })}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {errors.phone && <div className="error">{errors.phone.message}</div>}
              </div>
              <div className="form-group email">
                <label className="form-label">
                  {t("reservation_page.lbl_email")}
                  <span className="required">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control name"
                  value={email}
                  {...register("email", {
                    required: t("reservation_page.vld_email_required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("reservation_page.vld_email_invalid"),
                    },
                  })}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="error">{errors.email.message}</div>}
                {!emailSync && <div className="error">{t("reservation_page.vld_emailConfirm_identic")}</div>}
              </div>
              <div className="form-group email-confirm">
                <label className="form-label">
                  {t("reservation_page.lbl_email_confirm")}
                  <span className="required">*</span>
                </label>
                <input
                  {...register("emailConfirm", {
                    required: t("reservation_page.vld_emailConfirm_required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("reservation_page.vld_emailConfirm_invalid"),
                    },
                  })}
                  type="email"
                  name="emailConfirm"
                  className="form-control name"
                  value={emailConfirm}
                  onChange={(e) => setEmailConfirm(e.target.value)}
                />
                {errors.emailConfirm && <div className="error">{errors.emailConfirm.message}</div>}
                {!emailSync && <div className="error">{t("reservation_page.vld_emailConfirm_identic")}</div>}
              </div>
              <div className="form-group comments">
                <label className="form-label">
                  {t("reservation_page.lbl_comments")}
                </label>
                <textarea
                  type="text"
                  name="comments"
                  className="form-control"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>


              <div className="action-area">
                <div className="back">
                  <Link to="/profile">
                    <button type="button" className="btn btn-back">
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </Link>
                </div>
                <div className="submission">
                  <button
                    disabled={submitLoading}
                    type="submit"
                    className="btn btn-save"
                    onClick={() => handleSubmit()}
                  >
                    {t("reservation_page.button_submit")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default Reservation;
