import React from "react";
import Animate from "components/animate/animate";
import "./style.scss";
import verify_email from "assets/misc/verify_email.svg";

const VerifyEmail = ({error}) => {

  return (
    <Animate>
      <div className="auth-error">
        <div className="card">
          <div className="card-body">
            <img src={verify_email} alt="verify-email-error" />
            <h1>
              メールアドレスに認証メールを送信しましたのでご確認ください。
            </h1>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default VerifyEmail;
