import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import EcommerceLogin from "./ecommerce-login";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { isFestariaAcct } from "../../utils/Ecommerce";

const EcommerceRouter = ({ match }) => {
  const authConf = useContext(AuthConfigContext);
  return (
    <Switch>
      {isFestariaAcct(authConf?.configJson?.account) && (
        <>
          <Route
            exact
            path={`${match.url}`}
            component={() => {
              // window.location.href = (authConf?.configJson?.account === "1ba181f9" ? "https://onlineshop.festaria.jp/" : "https://fstrastg-ef.its.f-ace.jp/")
              window.location.href = "/agreement";
              return null;
            }}
          />
          <Route exact path={`${match.url}/login`} component={EcommerceLogin} />
        </>
      )}
    </Switch>
  );
};

export default EcommerceRouter;
