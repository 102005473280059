import { api_config } from "utils/APIConfig";
import { ACTION_POST, ACTION_PUT } from "utils/AxiosActions";


export const Ecommerce_Login = async (data, token) => {
  const auth = await api_config();
  const baseUrl = process.env.NODE_ENV === "development"
    ? "https://eff4d8fc.auth.dev.upbond.io"
    : `${auth.iss}`;

  auth.config.headers['Authorization'] = `Bearer ${token}`;
  auth.config.headers['X-origin'] = `${baseUrl}/`;
  auth.config.headers['Accept'] = 'application/json';
  auth.config.headers['Content-Type'] = 'application/json';
  const url = `${baseUrl}/login-festaria`;

  try {
    const result = await ACTION_POST(url, data, auth.config);
    return result;
  } catch (error) {
    return error;
  }
}

export const Register_Ecommerce_Member = async (data, token) => {
  const auth = await api_config();
  const baseUrl = process.env.NODE_ENV === "development"
    ? "https://eff4d8fc.auth.dev.upbond.io"
    : `${auth.iss}`;

  auth.config.headers['Authorization'] = `Bearer ${token}`;
  auth.config.headers['X-origin'] = `${baseUrl}/`;
  const url = `${baseUrl}/register-festaria`;

  try {
    const result = await ACTION_POST(url, data, auth.config);
    return result;
  } catch (error) {
    return error;
  }
}

export const Update_Ecommerce_Profile = async (data, token) => {
  const auth = await api_config();
  const baseUrl = process.env.NODE_ENV === "development"
    ? "https://eff4d8fc.auth.dev.upbond.io"
    : `${auth.iss}`;

  auth.config.headers['Authorization'] = `Bearer ${token}`;
  auth.config.headers['X-origin'] = `${baseUrl}/`;
  const url = `${baseUrl}/update-festaria`;

  try {
    const result = await ACTION_PUT(url, data, auth.config);
    return result;
  } catch (error) {
    return error;
  }
}

export const Delete_Ecommerce_Member = async (token) => {
  const auth = await api_config();
  const baseUrl = process.env.NODE_ENV === "development"
    ? "https://eff4d8fc.auth.dev.upbond.io"
    : `${auth.iss}`;

  auth.config.headers['Authorization'] = `Bearer ${token}`;
  auth.config.headers['X-origin'] = `${baseUrl}/`;
  const url = `${baseUrl}/withdraw-festaria`;
  const params = {
    "withdrawalReasonCode": "6"
  }

  try {
    const result = await ACTION_POST(url, params, auth.config);
    return result;
  } catch (error) {
    return error;
  }
}