import { api_config } from "utils/APIConfig";
import { ACTION_GET, ACTION_POST } from "utils/AxiosActions";

export const Profile_Info = async (token) => {
  const auth = await api_config();
  auth.config.headers['Authorization'] = `Bearer ${token}`
  const url = `${auth.mypage_host}/profile/`;
  const config = auth.config;

  return new Promise((resolve, reject) => {
    ACTION_GET(url, config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Profile_Update = async (data, token) => {
  const auth = await api_config()
  auth.config.headers['Authorization'] = `Bearer ${token}`

  const url = `${auth.mypage_host}/profile/`;
  const result = await ACTION_POST(url, data, auth.config);
  if (result) {
    return result;
  }
};

export const AutoComplete_ZipCode = (form) => {
  let formHasCustomData = !!form.formData.custom_data?.zipcode;
  
  let zipcode = form.formData.custom_data?.zipcode || form.formData.zipcode;
  const prefCodeVal = form.formData.custom_data?.prefCode || form.formData.prefCode;

  const zipcodeId = form.idSchema.custom_data?.zipcode?.$id || form.idSchema.zipcode?.$id;
  const prefCodeId = form.idSchema.custom_data?.prefCode?.$id || form.idSchema.prefCode?.$id;
  const cityId = form.idSchema.custom_data?.city?.$id || form.idSchema.city?.$id;
  if (zipcode && prefCodeId && cityId) {
    zipcode = zipcode.replaceAll(/[^\d]/g, '') // Replace Non digit string
    if (zipcode.length === 7 && !prefCodeVal) {
      // Call postcode to address API
      // Ref => https://postcode.teraren.com/doc
      ACTION_GET('https://postcode.teraren.com/postcodes/' + zipcode + '.json', {})
            .then((result) => {
              const options = document.querySelectorAll('#' + prefCodeId + ' option');
              const matchedOption = Array.from(options).find(option => option.text === result.prefecture);
              document.querySelector('#' + zipcodeId).value = zipcode;
              document.querySelector('#' + prefCodeId).value = matchedOption?.value;
              document.querySelector('#' + cityId).value = result.city + result.suburb;
              if (formHasCustomData) {
                form.formData.custom_data.zipcode = zipcode;
                form.formData.custom_data.prefCode = matchedOption?.value;
                form.formData.custom_data.city = result.city + result.suburb;
              }else {
                form.formData.zipcode = zipcode;
                form.formData.prefCode = matchedOption?.value;
                form.formData.city = result.city + result.suburb;
              }
            })
            .catch((error) => {
                console.log(error);
            });
    }
  }
}