import React from "react";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ onCancelClick }) => {
  const { t } = useTranslation();

  return (
    <div className="delete-modal">
      <div className="header section">
        <div className="head-block">
          <div className="title-bar"></div>
          <div className="title">{t("privacy_page.d_title")}</div>
          <div className="subtitle">{t("privacy_page.d_subtitle")}</div>
        </div>
      </div>
      <div className="content section">
        <p>{t("privacy_page.d_desc1")}</p>
        <p>{t("privacy_page.d_desc2")}</p>
        <input type="text" className="form-control" placeholder={t("privacy_page.input_type")} />
      </div>
      <div className="button-area section">
        <button className="btn btn-secondary" onClick={onCancelClick}>
          {t("privacy_page.btn_cancel")}
        </button>
        <button className="btn btn-danger">{t("privacy_page.btn_delete")}</button>
      </div>
    </div>
  );
};

export default DeleteModal;
