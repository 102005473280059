import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";

const UsePointsBtn = ({path, text, isLink = true, callback = null, bg = null ,disabled = false}) => {
  const { t } = useTranslation();

  const handleCallback = (e) => {
    if(callback) {
      return callback(e)
    } else {
      return null;
    }
  }

  return (
    <>
      {!isLink ? (
        <button style={{background: bg ? bg : '', border: bg ? bg : ''}} className="use-points-btn" disabled={disabled} onClick={(e) => handleCallback(e)}>
          {t(`${text}`)}
        </button>
        ) : (
        <Link className="use-points-btn" to={path}>
          {t(`${text}`)}
        </Link>
        )
      }
    </>
  );
}

export default UsePointsBtn;
