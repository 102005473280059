import img1 from "../../assets/image/ticket-mock/img1.jpg"
import img2 from "../../assets/image/ticket-mock/img2.jpg"
import img3 from "../../assets/image/ticket-mock/img3.jpg"
import img4 from "../../assets/image/ticket-mock/img4.jpg"
import img5 from "../../assets/image/ticket-mock/img5.jpg"
import img6 from "../../assets/image/ticket-mock/img6.jpg"
import img7 from "../../assets/image/ticket-mock/img7.jpg"

export const ticketsUpcoming = [
  {
    id: "1",
    price: {
      amount: 10000,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "002",
      name: "ビリーアイリッシュ",
      email: "tokyo-events.co.jp",
      location: {
        venue: "東京ドーム",
        is_online: false,
        formatted_address: "〒112-0004 東京都文京区後楽１丁目３−61",
        address: {
          zip_code: "112-0004",
          prefecture: "Tokyo",
          city: "Bunkyo City",
          street: " Koraku 1 Chome-3-61",
          building: "Tokyo Dome",
          office: "",
          floor: ""
        },
      },
      date: "2022-05-05T18:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img1,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      home_link: "https://www.tokyo-dome.co.jp/en/tourists/dome/",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "11",
        seats: [112]
      },
      event_type: "コンサート",
      event_details: [
        {
          artists: [
            "Billie Eilish"
          ],
          genre: "Pop",
          teams: []
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: false,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  },
  {
    id: "2",
    price: {
      amount: 8000,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "003",
      name: "読売ジャイアンツ",
      email: "tokyo-events.co.jp",
      location: {
        venue: "東京ドーム",
        is_online: false,
        formatted_address: "〒112-0004 東京都文京区後楽１丁目３−61",
        address: {
          zip_code: "112-0004",
          prefecture: "Tokyo",
          city: "Bunkyo City",
          street: " Koraku 1 Chome-3-61",
          building: "Tokyo Dome",
          office: "",
          floor: ""
        },
      },
      date: "2022-06-18T19:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img2,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      home_link: "https://www.tokyo-dome.co.jp/en/tourists/dome/",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "95",
        seats: [288]
      },
      event_type: "スポーツ",
      event_details: [
        {
          artists: [],
          genre: "",
          teams: [
            {
              name: "Tokyo Giants",
              logo_sm: "",
              logo_lg: ""
            },
            {
              name: "Hanshin Tigers",
              logo_sm: "",
              logo_lg: ""
            }
          ]
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: false,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  },
  {
    id: "3",
    price: {
      amount: 2500,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "003",
      name: "メトロポリタン",
      email: "metropolitan-info.org",
      location: {
        venue: "ニューヨーク",
        is_online: false,
        formatted_address: "1000 5th Ave, New York, NY 10028, United States",
        address: {
          zip_code: "10028",
          prefecture: "NY",
          city: "New York",
          street: "1000 5th Ave",
          building: "The Metropolitan Museum",
          office: "",
          floor: ""
        },
      },
      date: "2022-08-15T13:45:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img3,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      home_link: "https://www.metmuseum.org",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "",
        seats: []
      },
      event_type: "展示会",
      event_details: [
        {
          artists: [],
          genre: "Modern Art",
          teams: []
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: false,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  },
  {
    id: "4",
    price: {
      amount: 1500,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "564",
      name: "アートナイト",
      email: "tokyo-events.co.jp",
      location: {
        venue: "大阪中之島美術館",
        is_online: false,
        formatted_address: "〒530-0005 大阪府大阪市北区中之島４丁目３−1",
        address: {
          zip_code: "530-0005",
          prefecture: "Osaka",
          city: "Kita Ward",
          street: " 4 Chome-2-55 Nakanoshima",
          building: "Nakanoshima Museum",
          office: "",
          floor: ""
        },
      },
      date: "2022-08-12T18:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img4,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      home_link: "https://www.nmao.go.jp/",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "",
        seats: []
      },
      event_type: "展示会",
      event_details: [
        {
          // artists: [
          //   "Billie Eilish"
          // ],
          // genre: "Pop",
          // teams: []
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: false,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  },
  {
    id: "5",
    price: {
      amount: 10500,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "744",
      name: "GSウォリアーズ",
      email: "chase-center-events-info@gmail.com",
      location: {
        venue: "サンフランシスコ",
        is_online: false,
        formatted_address: "1 Warriors Way, San Francisco, CA 94158, United States",
        address: {
          zip_code: "94158",
          prefecture: "CA",
          city: "San Francisco",
          street: "1 Warriors Way",
          building: "Chase Center",
          office: "",
          floor: ""
        },
      },
      date: "2022-08-28T18:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img7,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
      home_link: "https://www.chasecenter.com/",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "12",
        section: "14",
        aisle: "37",
        seats: [119]
      },
      event_type: "スポーツ",
      event_details: [
        // {
        //   artists: [
        //     "Billie Eilish"
        //   ],
        //   genre: "Pop",
        //   teams: []
        // }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: false,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  }
]

export const ticketsPast = [
  {
    id: "6",
    price: {
      amount: 15000,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "022",
      name: "ダフト・パンク",
      email: "chicago-stadium-info.org",
      location: {
        venue: "シカゴ",
        is_online: false,
        formatted_address: "1901 W Madison St, Chicago, IL 60612, United States",
        address: {
          zip_code: "60612",
          prefecture: "IL",
          city: "Chicago",
          street: "1901 W Madison St",
          building: "Chicago Stadium",
          office: "",
          floor: ""
        },
      },
      date: "2022-03-23T20:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img6,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      home_link: "https://www.madhouseteamstore.com/",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "47",
        seats: [142]
      },
      event_type: "コンサート",
      event_details: [
        {
          artists: [
            "Daft Punk"
          ],
          genre: "Pop",
          teams: []
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: true,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  },
  {
    id: "7",
    price: {
      amount: 7500,
      currency: "¥"
    },
    owner: {
      name: "山田優子",
      email: "yayuko@gmail.io",
      attendees: "1"
    },
    event: {
      id: "242",
      name: "フー・ファイターズ",
      email: "estadio-bernabeu.com",
      location: {
        venue: "マドリッド",
        is_online: false,
        formatted_address: "Av. de Concha Espina, 1, 28036 Madrid, Spain",
        address: {
          zip_code: "28036",
          prefecture: "Madrid",
          city: "Madrid",
          street: "Av. de Concha Espina, 1",
          building: "Santiago Bernabéu Stadium",
          office: "",
          floor: ""
        },
      },
      date: "2022-01-17T18:30:00.000+00:00",
      doors_open_time: "30 min before",
      cover_photo: img5,
      extra_photos: [],
      logo: "",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      home_link: "estadio-bernabeu.com",
      share_links: {
        twitter: "",
        facebook: ""
      },
      seating: {
        level: "",
        section: "",
        aisle: "B",
        seats: [122]
      },
      event_type: "コンサート",
      event_details: [
        {
          artists: [
            "The Foo Fighters"
          ],
          genre: "Rock",
          teams: []
        }
      ],
      event_status: "Confirmed",
      is_soldout: false
    },
    is_over: true,
    date_issued: "2022-04-20T05:35:55.519+00:00",
    is_verified: true
  }
]