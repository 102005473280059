import { orders_config, queryParam } from "utils/APIConfig";
import { ACTION_GET, ACTION_POST, ACTION_PUT } from "utils/AxiosActions";

export const Get_Orders = async (body) => {
    const _conf = await orders_config();
    const url = `${_conf.config.endpoints.order_url}`
    if (body.userId) {
        body.userId = String(body.userId);
    }
    // const url = `${_conf.config.endpoints.order_url}?${queryParam({
    //     userId: userId,
    //     page: page,
    //     numeber: number
    // })}`;

    console.log('get order payload...');
    console.log(body);

    return new Promise((resolve, reject) => {
        ACTION_POST(url, body, _conf.config)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}