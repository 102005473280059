import React from "react";
import Animate from "components/animate/animate";
import "./style.scss";

const PointsHomeOutsideCard = () => {
  return (
    <Animate>
      <div className="point-description">
        <p>【ポイントのご利用単位】</p>
        <p>
          オンラインショップでは
          <br />
          1ポイントよりご利用いただけます。
          <br />
          店舗では1,000ポイント単位で
          <br />
          ご利用いただけます。
        </p>
        <p>【ポイントの有効期限】</p>
        <p>ポイント付与日から１年間</p>
        <p>【会員ランク表】</p>
        <p>
          直近1年間の累計購入金額に応じて会員ランクと
          <br />
          ポイント付与率が変わります。
          <br />
          プラチナランクのお客さまの場合、お買上げ毎に
          <br />
          購入金額(税込)の3%分のポイントが付与されます。
        </p>
      </div>
      <div className="point_table_wrapper">
        <table className="point_table" border="1">
          <tbody>
            <tr>
              <th>ポイントランク</th>
              <th>ダイヤモンド</th>
              <th>プラチナ</th>
              <th>ゴールド</th>
              <th>シルバー</th>
            </tr>
            <tr>
              <td>累計購入金額(税込)</td>
              <td>100万円以上</td>
              <td>50万円以上</td>
              <td>10万円以上</td>
              <td>10万円未満</td>
            </tr>
            <tr>
              <td>ポイント付与率</td>
              <td>5％</td>
              <td>3％</td>
              <td>2％</td>
              <td>1％</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Animate>
  );
};

export default PointsHomeOutsideCard;
