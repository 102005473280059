import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

const TicketDetails = ({event, owner, is_verified, formatDate, formatTime, formatSeating}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-100 d-flex flex-column align-items-start pb-4 px-4">
        <p className="fs-5 fw-bold">{t("tickets_page.ticket_page.ticket_details.details")}</p>
        <Card className="w-100 d-flex align-items-start py-4 gap-2 shadow border-0" style={{borderRadius: "16px"}}>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="far fa-calendar-alt"></i>
                <span className="fs-12 px-1">{formatDate(event.date)}</span>
              </span>
            </Col>
            <Col>
              <span className="d-flex align-items-center justify-content-end">
                <i className="fas fa-user"></i>
                <span className="fs-12 px-1">{owner.name}</span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                <i className="fas fa-clock"></i>
                <span className="fs-12 px-1">{formatTime(event.date)}</span>
              </span>
            </Col>
            <Col>
              <span className="d-flex align-items-center justify-content-end">
                <i className="fas fa-chair"></i>
                <span className="fs-12 px-1 lh-1">{formatSeating(event.seating)}</span>
              </span>
            </Col>
          </Row>
          <Row className="w-100 m-0">
            <Col>
              <span className="d-flex align-items-center justify-content-start">
                {event.location.is_online ? <i className="fas fa-globe"></i> : <i className="fas fa-globe-americas"></i>}
                <span className="fs-12 px-1">{event.location.is_online ? t("tickets_page.ticket_page.ticket_details.online") : t("tickets_page.ticket_page.ticket_details.offline")}</span>
              </span>
            </Col>
            <Col>
            <span className="d-flex align-items-center justify-content-end">
              {is_verified ? <i className="fas fa-check-circle"></i> : <i className="fas fa-times-circle"></i>}
              <span className="fs-12 px-1">{is_verified ? t("tickets_page.ticket_page.ticket_details.verified") : t("tickets_page.ticket_page.ticket_details.not_verified")}</span>
            </span>
            </Col>
          </Row>
        </Card>
      </div>
      <Row className="w-75"><hr/></Row>
    </>
  )
}

export default TicketDetails;