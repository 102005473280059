import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animate from "components/animate/animate";
import './style.scss'

const ReservationFinal = () => {
  const { t } = useTranslation();

  return (
    <Animate>
      <div className="reservation-final">
        <div className="card">
          <div className="card-title">
            <h1>{t("reservation_page.final.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className="subtitle"><p>{t("reservation_page.final.subtitle")}</p></div>
            <div className="return-btn">
              <Link to="/home">
                <button>{t("reservation_page.final.return")}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
};

export default ReservationFinal;