import React from "react";
import { Switch, Route } from "react-router-dom";
import Reservation from "./reservation"
import ReservationConfirm from "./reservation-confirm"
import ReservationFinal from "./reservation-final"
import ReservationList from "./reservation-list"

const ReservationRouter = ({match}) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Reservation}/>
      <Route exact path={`${match.url}/confirm`} component={ReservationConfirm}/>
      <Route exact path={`${match.url}/final`} component={ReservationFinal}/>
      <Route exact path={`${match.url}/list`} component={ReservationList}/>
    </Switch>
  )
}

export default ReservationRouter;