import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import PointsHome from "./points-home";
import PointsHomeOutsideCard from "./points-home/outside-card";
import PointsInput from "./points-input";
import PointsConfirm from "./points-confirm";
import { AuthConfigContext } from "context/AuthConfigProvider";
import "./style-wrapper.scss"

const PointsRouter = ({match}) => {
  const authConf = useContext(AuthConfigContext);

  return (
    <div className="points-page">
      <div className="card">
        <div className="logo-zone">
          {authConf.configJson && authConf.configJson.brand_ui && authConf.configJson.brand_ui.logo ? (
            <img src={authConf.configJson.brand_ui.logo} alt="Brand logo"/>
          ) :
            <></>
          }
        </div>
        <Switch>
          <Route exact path={`${match.url}`} component={PointsHome} />
          <Route exact path={`${match.url}/input`} component={PointsInput} />
          <Route exact path={`${match.url}/confirm`} component={PointsConfirm} />
        </Switch>
      </div>
      <Switch>
          <Route exact path={`${match.url}`} component={PointsHomeOutsideCard} />
      </Switch>
    </div>
  )
}

export default PointsRouter;