export const ccDataForm = () => {
  const htmlContent = `<div class="form-register">
    <div class="form-group">
      <label htmlFor="cc-name" class="form-label">
        Name (as it appears on your card)
      </label>
      <input
        type="text"
        id="cc-name"
        name="name"
        class="form-control name"
      />
    </div>

    <div class="form-group">
      <label htmlFor="cc-creditcard" class="form-label">
        Credit Card Number
      </label>
      <div class="input-container">
        <input
          type="text"
          id="cc-creditcard"
          name="cc"
          class="form-control cc"
          maxLength="16"
        />
        <div class="logo-area">
          <i class="fab fa-cc-visa"></i>
          <i class="fab fa-cc-mastercard"></i>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col">
        <label htmlFor="cc-valid-mm" class="form-label">
          Valid Through
        </label>
        <div class="row">
          <input
            type="text"
            id="cc-valid-mm"
            name="valid-mm"
            class="form-control valid-mm col"
            placeholder="MM"
            maxLength="2"
          />
          <input
            type="text"
            id="cc-valid-yy"
            name="valid-yy"
            class="form-control valid-yy col"
            placeholder="YY"
            maxLength="2"
          />
        </div>
      </div>
      <div class="form-group col">
        <label htmlFor="cc-security" class="form-label">
          Security Code
        </label>
        <input
          type="text"
          id="cc-security"
          name="security"
          maxLength="3"
          class="form-control security"
          placeholder="***"
          maxLength="3"
        />
      </div>
    </div>
  </div>`;

  return htmlContent;
};

export const walletDataForm = () => {
  const htmlContent = `<div class="form-register">
    <div class="form-group">
      <label htmlFor="wallet-name" class="form-label">
        Name
      </label>
      <input
        type="text"
        id="wallet-name"
        name="name"
        class="form-control name"
      />
    </div>

    <div class="form-group">
      <label htmlFor="wallet-number" class="form-label">
        Wallet Number
      </label>
      <div class="input-container">
        <input
          type="text"
          id="wallet-number"
          name="number"
          class="form-control cc"
          maxLength="16"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col">
        <label htmlFor="wallet-security" class="form-label">
          Security Code
        </label>
        <input
          type="text"
          id="wallet-security"
          name="security"
          maxLength="3"
          class="form-control security"
          maxLength="3"
        />
      </div>
    </div>
  </div>`;
  return htmlContent;
}