import React, { useState, useEffect, useContext } from "react";
import Animate from "components/animate/animate";
import { useHistory } from "react-router-dom";
import { Delete_Ecommerce_Member } from "services/Ecommerce";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorTop from "components/error/error-top";
import Swal from "sweetalert2";
import { AuthConfigContext } from "context/AuthConfigProvider";
import "./style.scss";

const Withdraw = () => {
  const authConf = useContext(AuthConfigContext);
  const history = useHistory();
  const [token, setToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const deleteUser = async () => {
    console.log('node_env:', process.env.NODE_ENV);
    const returnUrl = process.env.NODE_ENV === "development"
      ? `https://eff4d8fc.auth.dev.upbond.io/v2/logout?client_id=${authConf.configJson.client_id}`
      : `${authConf.configJson.domain}/v2/logout?client_id=${authConf.configJson.client_id}`

    const deletedRes = await Delete_Ecommerce_Member(token);
    // console.log(deletedRes);
    if (deletedRes.message === 'Success') {
      // console.log('successfully deleted member:', deletedRes);
      Swal.fire({
        icon: "success",
        title: "退会成功",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
        window.location.href = returnUrl;
      }, 3000);
    } else {
      // console.log('delete member error:', deletedRes );
      setError(true);
      let errorDescription = deletedRes?.errors?.error_description;
      if (errorDescription) {
        setErrorMessage(errorDescription)
      } else {
        setErrorMessage("退会できませんでした");
      }
    }
  }

  useEffect(() => {
    const getToken = async () => {
      try {
        const tkn = await getAccessTokenSilently();
        if (tkn) {
          localStorage.setItem("accesstoken", token);
          setToken(tkn)
        }
      } catch (error) {
        console.log('token generation error: ', error);
      }
    };

    let mounted = true;
    if (mounted) {
      getToken();
    }
    return () => (mounted = false);
  }, [getAccessTokenSilently, token])

  useEffect(() => {
    // Reset error message after 7 seconds
    setTimeout(() => {
      setError(false)
      setErrorMessage('')
    }, 7000);
  }, [error, errorMessage])

  return (
    <Animate>
      <div className="withdraw-membership-page">
        <div className="card">
          {error && <ErrorTop message={errorMessage} />}
          <div className="card-title">
            <h1>退会</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <p>
              <p>LINEの通知が多くてお困りの場合は、公式LINEアカウントを「通知オフ」にすることで通知を止めることができます。</p>
              <p>「＜」ボタンで引き続き会員向けサービスをご利用ください。退会をご希望の場合は、以下のご注意をご確認のうえ、「上記に同意して退会する」ボタンを押してください。</p>

              <p className="card-body-cotion">ご注意</p>
              ・「festaria Members Club」から退会すると、会員向けサービスはすべてご利用いただけなくなります。退会後の復元はできませんので、ご注意ください。
              <br /><br />
              ＜無効となる会員向けサービス＞<br></br>
              たまっているポイントのご利用<br></br>
              新規のポイント獲得<br></br>
              ご購入履歴の確認
              <br /><br />
              ・退会後に再登録しても、退会以前の会員向けサービスの履歴を復元することはできません。<br /><br />
            </p>
            <div className="action-area">
              <div className="back">
                <button type="button" className="btn btn-back" onClick={() => history.goBack()}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </div>
              <div className="submission">
                <button
                  type="button"
                  className="btn btn-delete"
                  onClick={() => deleteUser()}
                >
                  上記を同意して退会する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default Withdraw;