import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { api_config } from "utils/APIConfig";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.scss";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#ccc",
      color: "#808080",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#000" },
      "::placeholder": { color: "#ccc" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const PaymentForm = ({ data }) => {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  // const [stripePaymentData, setStripePaymentData] = useState(null);
  const [purchaseData, setPurchaseData] = useState(null);

  const [toast, setToast] = useState(false);
  const [toastTextStatus, setToastTextStatus] = useState(true);
  const [toastText, setToastText] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnDisable(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    // 4242 4242 4242 4242
    // 4000 0000 0000 3220

    if (!error) {
      try {
        const { id } = paymentMethod;
        const url_stripe = "https://payment-stripe.dev.upbond.io/customer/create-payment-intent";
        const paramStripe = {
          amount: 200,
          currency: "jpy",
          paymentMethodType: "card",
          paymentMethod: id,
          metadata: {
            id: "test-id",
            customer_id: "485",
            name: "Testing NFT Purchase #1",
            remark: `Payment NFT ${id} with credit card`,
            url: "https://5af212ee.dev.upbond.io/t/5af212ee/1",
          },
        };

        const response = await axios.post(url_stripe, paramStripe, api_config.config);
        if (response.data.clientSecret) {
          const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(response.data.clientSecret, {
            payment_method: id,
          });

          if (stripeError) {
            console.log(stripeError.message);
          }

          console.log("Stripe Payment:", paymentIntent);
          // setStripePaymentData(paymentIntent);

          if (paymentIntent) {
            setToast(true);
            setToastText("confirm success");
            const url_purchase = `${api_config.wallet_host}/integration-purchase-nft/${data.NFTID}`;
            const paymentID = paymentIntent.id;
            const paramPurchase = {
              payment_type: "card",
              payment_id: paymentID,
            };
            const purchase = await axios.post(url_purchase, paramPurchase, api_config.config);
            if (purchase) {
              setToast(false);
              console.log("Purchase Data:", purchase.data.data);
              setPurchaseData(purchase.data.data);
              setSuccess(true);
              setBtnDisable(false);
            }
          }
        }
      } catch (error) {
        console.log("iki error", error.message);
        setToastTextStatus(false);
        setToastText(error.message);
        setBtnDisable(false);
      }
    } else {
      console.log("ono error:", error.message);
      setBtnDisable(false);
    }
  };

  const handleCancel = () => {
    if (!btndisable) {
      data.onCancelClick();
    }
  };

  const SuccessConfirm = () => {
    Swal.fire({
      title: t("payment.success_title"),
      text: t("payment.success_subtitle"),
      icon: "success",
      cancelButtonText: t("payment.btn_close"),
      confirmButtonText: t("payment.btn_linkTransaction"),
      confirmButtonColor: "#199f19",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (purchaseData.linkTransaction) {
          window.open(purchaseData.linkTransaction, "_blank");
        }
      }
    });
    return null;
  };

  const ShowToastText = () => {
    if (toastText === "confirm success") {
      return (
        <>
          <div className="title">{t("payment.toast_success_title")}</div>
          <div className="subtitle">{t("payment.toast_success_subtitle")}</div>
        </>
      );
    } else if (toastText === "Request failed with status code 400") {
      return (
        <>
          <div className="title">{t("payment.toast_error_title")}</div>
          <div className="subtitle">{t("payment.toast_error_400_subtitle")}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="title">{t("payment.toast_error_title")}</div>
          <div className="subtitle">{t("payment.toast_error_subtitle")}</div>
        </>
      );
    }
  };

  return (
    <>
      {success ? (
        <SuccessConfirm />
      ) : (
        <form className="payment-form" onSubmit={handleSubmit}>
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <div className="button-area">
            <button type="button" className="btn-cancel" onClick={handleCancel}>
              {t("payment.btn_cancel")}
            </button>
            <button type="submit" disabled={btndisable} className="btn-pay">
              {btndisable ? <span className="loading spinner-border"></span> : "Pay"}
            </button>
          </div>
          <div className={`confirm-status ${toast ? "show" : ""} ${toastTextStatus ? "green" : "red"}`}>
            <ShowToastText />
          </div>
        </form>
      )}
    </>
  );
};

export default PaymentForm;
