export const isFestariaAcct = (acct) => {
  let approvedAccts = ['eff4d8fc', '1ba181f9'];
  return (approvedAccts.includes(acct) ? true : false);
}

export const hasFestariaId = (identities) => {
  if(identities?.length > 0) {
    return identities.find(identity => identity?.provider === 'festaria');
  }
}

export const hasLineId = (identities) => {
  if(identities?.length > 0) {
    return identities.find(identity => identity?.provider === 'line');
  }
}