import React from "react";
import { Container } from "react-bootstrap";

export const CustomContainer = ({children}) => {
  return (
    <Container className="container-custom bg-white d-flex flex-column justify-content-left">
      {children}
    </Container>
  )
}

export default CustomContainer;