import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animate from "components/animate/animate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DeleteModal from "./delete";
import "./style.scss";

// TODO: delete once feature becomes available
// import comingsoon from "assets/misc/comingsoon.svg";

const MySwal = withReactContent(Swal);

const DataPrivacy = () => {
  const { t } = useTranslation();
  const [mobileScreen, setMobileScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setMobileScreen(false);
    } else {
      setMobileScreen(true);
    }
  }, []);

  const consents = [
    {
      id: "1",
      name: "FDCP",
      connection: "LINE",
      consent: [
        { id: "a", type: t("privacy_page.lbl_profile"), status: { type: "check", value: true } },
        { id: "b", type: t("privacy_page.lbl_fullname"), status: { type: "required", value: true } },
        { id: "c", type: t("privacy_page.lbl_phone"), status: { type: "switch", value: true } },
        { id: "d", type: t("privacy_page.lbl_birthday"), status: { type: "switch", value: false } },
      ],
      date: "07/11/2020",
      status: [
        { type: "check", value: true },
        { type: "required", value: true },
        { type: "switch", value: true },
        { type: "switch", value: false },
      ],
    },
    {
      id: "2",
      name: "FUKUSUKE",
      connection: "",
      consent: [{ id: "a", type: t("privacy_page.lbl_email"), status: { type: "check", value: true } }],
      date: "23/10/2020",
      status: [{ type: "check", value: true }],
    },
  ];

  const showStatus = (data) => {
    if (data) {
      if (data.type === "check") {
        return (
          <>
            <i className={`fas fa-check-circle ${data.value ? "active" : ""}`}></i>
            <i className="fas fa-chevron-down"></i>
          </>
        );
      } else if (data.type === "required") {
        return data.value ? "required" : "optional";
      } else {
        return (
          <div className="form-check form-switch">
            <input
              id="flexSwitchCheckChecked"
              className="form-check-input"
              type="checkbox"
              role="switch"
              onChange={(e) => handleSwitchChange(e, data.value)}
              checked={data.value}
            />
          </div>
        );
      }
    } else {
      return "";
    }
  };

  const handleSwitchChange = (e, data) => {
    console.log(e.target.value);
  };

  const onCancel = () => {
    MySwal.close();
  };

  const deleteModal = () => {
    MySwal.fire({
      html: <DeleteModal onCancelClick={onCancel} />,
      showConfirmButton: false,
      customClass: "swal-delete-modal",
    });
  };

  const TableMobile = () => {
    return (
      <div className="table-mobile">
        {consents.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <div className="tablebox">
                <div className="segment application">
                  <div className="title">{t("privacy_page.th_application")}</div>
                  <div className="content">{item.name}</div>
                </div>
                <div className="segment connection">
                  <div className="title">{t("privacy_page.th_connection")}</div>
                  <div className="content">{item.connection ? item.connection : "-"}</div>
                </div>
                <div className="segment consent">
                  <div className="title">{t("privacy_page.th_consent_type")}</div>
                  <div className="content">
                    {console.log(item.consent)}
                    {item.consent.map((consent) => {
                      return (
                        <div className="consent-type" key={consent.id}>
                          <div className="label">{consent.type}</div>
                          <div className="value">{showStatus(consent.status)}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const TableDesktop = () => {
    return (
      <table className="table table-desktop table-borderless">
        <thead>
          <tr>
            <th>{t("privacy_page.th_application")}</th>
            <th>{t("privacy_page.th_connection")}</th>
            <th>{t("privacy_page.th_consent_type")}</th>
            <th>{t("privacy_page.th_date")}</th>
            <th>{t("privacy_page.th_status")}</th>
          </tr>
        </thead>
        <tbody>
          {consents.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <tr className="consent-apps">
                  <td className="application">{item.name}</td>
                  <td className="connection">{item.connection}</td>
                  <td className="consent">{item.consent[0].type}</td>
                  <td className="date">{item.date}</td>
                  <td className="status">{showStatus(item.consent[0].status)}</td>
                </tr>
                {item.consent.shift() &&
                  item.consent.map((consent) => {
                    return (
                      <tr key={consent.id}>
                        <td className="blank-td" colSpan={2}></td>
                        <td colSpan={2}>{consent.type}</td>
                        <td>{showStatus(consent.status)}</td>
                      </tr>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Animate>
      <div className="data-privacy-page">
        <div className="card">
          <div className="card-title">
            <h1>{t("privacy_page.title")}</h1>
            <Link className="edit" to="#">
              <i className="fas fa-pen"></i>
              {t("profile_page.btn_edit")}
            </Link>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            <div className="section activity">
              <div className="title">{t("privacy_page.consent_history")}</div>
              {mobileScreen ? <TableMobile /> : <TableDesktop />}
            </div>
            <div className="section delete">
              <div className="text">{t("privacy_page.delete_account")}</div>
              <button className="btn btn-danger" onClick={deleteModal}>
                <i className="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default DataPrivacy;
