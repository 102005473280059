import React from "react";
import { Link } from "react-router-dom";
import "./style.scss"
import { useTranslation } from "react-i18next";

const CancelBtn = ({text, path}) => {
  const { t } = useTranslation();

  return (
    <Link className="cancel-btn" to={path}>
      {t(`${text}`)}
    </Link>
  );
}

export default CancelBtn;
