import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Animate from "components/animate/animate";
import QRCode from "react-qr-code";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { Link } from "react-router-dom";
import HeaderMobile from "../../components/headerMobile";
import { ECommerceHomePage } from "components/ecommerce/ecommerce-home-page";
import { isFestariaAcct } from "utils/Ecommerce";
import Barcode from 'react-barcode';
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

import "./style.scss";

const Home = () => {
  const authConf = useContext(AuthConfigContext);
  const { t } = useTranslation();
  const [profileDetails] = useContext(ProfileDetailsContext);

  const formatID = (id) => {
    let starter = "0000000000";
    const idLength = id.length;
    return starter.substring(idLength) + id;
  }

  return (
    <Animate>
      <HeaderMobile />
      <div className="home-page">
        <div className="header-area">
          <div className="logo-zone">
            {authConf.configJson && authConf.configJson.brand_ui && authConf.configJson.brand_ui.logo ? (
              <img src={authConf.configJson.brand_ui.logo} alt="Brand logo"/>
            ) : (
              <></>
            )}
          </div>
          <div className="personal-info-zone">
            <div className="profile-zone">
              {profileDetails && profileDetails.picture ? (
                <img src={profileDetails.picture} alt="user-pic" />
              ) : (
                <i className="fas fa-user"></i>
              )}
            </div>
            <div className="biodata">
              <ul>
                <li className="name">
                  {
                    profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? "" :
                    profileDetails && profileDetails.family_name && profileDetails.name ? profileDetails.family_name + ' ' + profileDetails.name :
                    profileDetails && profileDetails.name ? profileDetails.name :
                    "New User"
                  }{" "}{
                    profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? t("homepage.temporary_member") : 
                    t("homepage.honorary")
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="divider"></div> */}
        <div className="qrcode-area">
          {isFestariaAcct(authConf?.configJson?.account) ? (
            <>
              <div className="caption">{t("homepage.showBarCode")}</div>
              {profileDetails?.festaria_id &&
                <div className="barcode">
                  <Barcode
                    value={profileDetails?.festaria_id}
                    format="EAN13"
                    flat={true}
                    textPosition="bottom"
                    textAlign="center"
                    fontSize={22}
                    textMargin={10}
                    width={3}
                    height={60}
                  />
                </div>
              }
            </>
          ) : (
            <>
              <div className="caption">{t("homepage.showQRCode")}</div>
              {profileDetails && profileDetails.id &&
                <div className="qrcode">
                    <QRCode value={profileDetails.id} size={125} />
                    <p>{formatID(profileDetails.id)}</p>
                </div>
              }
            </>
          )}
        </div>
        <div>
          {isFestariaAcct(authConf?.configJson?.account) ? (
            <ECommerceHomePage profile={profileDetails} />
          ) : (
            <Link to="/profile">
              <div className="customer-info">
                <button>会員情報はこちら</button>
              </div>
            </Link>
          )}
        </div>
      </div>
    </Animate>
  );
};

export default Home;
