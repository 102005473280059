import { points_config } from "utils/APIConfig";
import { ACTION_GET, ACTION_POST, ACTION_PUT } from "utils/AxiosActions";

export const Points_History = async (body) => {
  const _conf = await points_config();
  const url = _conf.config.endpoints.history_point_url;
  if (body.userId) {
      body.userId = String(body.userId);
  }

  return new Promise((resolve, reject) => {
    ACTION_POST(url, body, _conf.config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const Add_Point = async (body) => {
  const _conf = await points_config();
  const url = _conf.config.endpoints.add_point_url;
  if (body.userId) {
      body.userId = String(body.userId);
  }

  return new Promise((resolve, reject) => {
    ACTION_POST(url, body, _conf.config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const Sub_Point = async (body) => {
  const _conf = await points_config();
  const url = _conf.config.endpoints.subpoint_url;
  if (body.userId) {
      body.userId = String(body.userId);
  }

  return new Promise((resolve, reject) => {
    ACTION_POST(url, body, _conf.config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const Get_Balance = async (body) => {
  const _conf = await points_config();
  const url = _conf.config.endpoints.balance_point_url;
  if (body.userId) {
      body.userId = String(body.userId);
  }

  console.log('get balance payload...');
  console.log(body);

  return new Promise((resolve, reject) => {
    ACTION_POST(url, body, _conf.config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const Use_Points = async (body) => {
  const _conf = await points_config();
  const url = _conf.config.endpoints.sub_point_url;
  if (body.userId) {
      body.userId = String(body.userId);
  }

  return new Promise((resolve, reject) => {
    ACTION_POST(url, body, _conf.config)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });

};