import React from "react";
import Animate from "components/animate/animate";
// import BackHome from "components/backhome-button";
import { ticketsUpcoming, ticketsPast } from "../ticket-data";
import { useTranslation } from "react-i18next";
import TicketsDisplay from "../components/TicketsDisplay";
import CustomContainer from "../components/CustomContainer";
import PageTitle from "../components/PageTitle";
// import CategoriesDisplay from "../components/CategoriesDisplay";

const Tickets = () => {
  const { t } = useTranslation();

  return (
    <Animate>
      <CustomContainer>
        <PageTitle title={t("tickets_page.title")}/>
        {/* <CategoriesDisplay title={t("tickets_page.categories")}/> */}
        <TicketsDisplay title={t("tickets_page.upcoming")} tickets={ticketsUpcoming}/>
        <TicketsDisplay title={t("tickets_page.past")} tickets={ticketsPast}/>
      </CustomContainer>
      {/* <BackHome /> */}
    </Animate>
  );
};

export default Tickets;
